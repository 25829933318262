import {Dialog,DialogTitle,DialogActions,Button} from "@mui/material"
import "./DeleteModal.css"
export default function SimpleDialog(props) {
    const { onClose, open, handleRevokeSubmit,actionType } = props;
  
    const handleClose = () => {
      onClose();
    };
  
    return (
      <Dialog onClose={handleClose} open={open} className="deleteDialog">
        <DialogTitle>Are you sure want to {actionType} the Persona Access?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} sx={{color:'red'}}>Cancel</Button>
          <Button onClick={handleRevokeSubmit} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }