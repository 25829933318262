import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import _ from "lodash";
import { debounce } from "lodash";
import axios from "axios";
import "./landingpage.css";
import Background from "../assets/pcore_image.png";
import { useOktaAuth } from "@okta/okta-react";
import MUIDataTable from "mui-datatables";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// import { useHistory } from "react-router-dom";
import clsx from "clsx";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  centeredHeader: {
    "& span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "15px",
      fontWeight: "600",
    },
  },

  centeredrow: {
    "& span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "15px",
      fontWeight: "400",
    },
  },
  customTextfield: {
    height: "33px",
    fontSize: "16px",
    // Add any other custom styles you need
  },
}));



//const DataCl = testdata.data_classification;
function Identifers(test) {
  var inditm = "";
  {
    test.map((itm) => {
      if (inditm == "") {
        inditm = itm;
      } else {
        inditm = inditm + ", " + itm;
      }
    });
  }
  return inditm;
}

const AccessReviewPage = () => {
  //const data = DgPage('get-pii-summary-table');
  const Url = process.env.BASE_URL;
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;
  const params = new URLSearchParams([["email", Email]]);
  const { oktaAuth } = useOktaAuth();
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const [tables, setTables] = useState([]);
  const [roles, setRoles] = useState([]);
  const [tableData, setTableData] = useState([]);
  // const [filtered, setfiltered] = useState([]);
  const [filtered, setfiltered] = useState([]);
  const [identifiers, setIdentifiers] = useState([]);
  const [db, setDB] = useState([]);
  const [schema, setSchema] = useState([]);
  // const [handleSelectBool, setHandleSelectBool] = useState(true)
  // const [filterInitialized,setFilterInitialized]=useState(false)
  const [filter, setFilter] = useState({
    source: "",
    identifiers: "",
    table_name: "",
    schema: "",
    data_catalog: "",
    users: "",
    roles:''
  });
  const [userInput, setUserInput] = useState("");
  var Email = oktaAuth.authStateManager._authState.idToken.claims.email;

  useEffect(() => {    
  let id = toast.loading("Please wait, Fetching data...")

  axios
  .get(Url + `get-dpds-sf-pii-persona-mapping`, {
    
    headers: {
      "rum-svc-acct": Svc,
      "rum-api-key": API_KEY,
    },
  })
  .then((data) => {
    toast.dismiss(id)
    toast.success("Data loaded successfully",{autoClose:750})
        // toast.update(id, { render: "Data loaded successfully", type: "success",autoClose:1000, isLoading: false });
        // console.log(data, "pii data-", typeof data.data.result);
        let startTime=performance.now()
        const data1 = JSON.parse(data.data.result)
        // const data1 = sampleData;
        const modifiedData = data1.map((ele) => {
          let splitArr = ele.OBJECT_NAME.split(".");
          return {
            data_catalog: splitArr[0],
            schema: splitArr[1],
            table_name: splitArr[2],
            identifiers: ele.IDENTIFIERS,
            users: JSON.parse(ele.users),
          };
        });
        // console.log(data1, "data1");
        const piiData = Object.keys(data1).map((item, index) => {
          return data1[item];
        });
        // console.log(data, "pii data-", piiData);
        
        getValues(modifiedData);
        
        let endTime=performance.now()
        // console.log(endTime-startTime,'milliSecs','StartTIme:',startTime,"EndTime:",endTime);
        setIsLoading(false)
        setTableData(modifiedData);
        setfiltered(modifiedData);
      })
      .catch((err) => {
        toast.dismiss(id)
        toast.error("Data loading failed.",{autoClose:1500})
        // toast.update(id, { render: "Data loading failed.", type: "error",autoClose:5000, isLoading: false });
        setIsLoading(false)
        console.log(err)});
  }, []);

  // const dataIndex = {
  //   source: 3,
  //   identifiers: 4,
  //   data_catalog: 0,
  //   schema: 1,
  //   table_name: 2,
  // };

  const getValues = (data1) => {
    let val = ["object_name"],
      temp = [{ label: "All", value: "select" }],
      dbName = [],
      temp2 = [],
      roleArr = [],
      schema = [];
    _.map(data1, (i) => {
      let obj = {},
        obj2 = {};
      const tableExists = _.some(temp, { value: i.table_name });
      if (!tableExists) {
        obj.label = i.table_name;
        obj.value = i.table_name;

        temp.push(obj);
      }
      if (!_.includes(dbName, i.data_catalog)) {
        dbName.push(i.data_catalog);
      }
      if (!_.includes(schema, i.schema)) {
        schema.push(i.schema);
      }
      // debugger
      _.map(i.users, (user_item) => {
        // debugger
        // _.map(JSON.parse(user_item?.roles), (j) => {
        _.map(user_item?.roles, (j) => {
          // debugger
          if (!_.includes(roleArr, j)) {
            roleArr.push(j);
          }
        });
      });
    });
    _.map(data1, (i, index) => {
      // debugger
      _.map(JSON.parse(i.identifiers), (j) => {
        // debugger
        if (!_.includes(temp2, j)) {
          temp2.push(j);
        }
      });
    });

    setTables(temp);
    // setRoles(roleArr)
    setDB(selectVal(_.uniq(dbName)));
    setRoles(selectVal(_.uniq(roleArr)));
    setSchema(selectVal(_.uniq(schema)));
    setIdentifiers(selectVal(_.uniq(temp2)));
  };

  const selectVal = (data, val) => {
    let temp = [{ label: "All", value: "select" }];
    _.map(data, (i) => {
      let obj = {};
      obj.label = val == undefined ? i : i.val;
      obj.value = val == undefined ? i : i.val;

      temp.push(obj);
    });
    return temp;
  };

  const handleSelect = (e, type, temp) => {
    //debugger;
    // console.log(e, "value", tableData, e.length, type, temp);
    let data = tableData,
      str,
      arr = [],
      temp_arr = [],
      filterInitialized = false,
      handleSelectBool = true,
      check = [];
    // console.log(filter, "filters");
    let fil = filter;
    fil[temp] = e.length > 0 ? e : "";
    if (temp == "users") {
      fil[temp] = e.target.value;
    }

    Object.keys(fil).forEach((key, index) => {
      fil[key] != "" ? check.push(fil[key]) : "";
    });
    setFilter(fil);

    Object.keys(fil).forEach((key, obj_index) => {
    
      // console.log(key, fil[key], "key");
      let filArray = fil[key];
      if (filArray.value != "select" && check.length > 0) {
        // if (filArray.value != "select" && filArray.length > 0) {
        if (filArray != "") {
      

          if (key == "identifiers") {
       
            const mapFilterInitialized = filterInitialized;
            _.map(filArray, (item, index) => {
              const filteredData = _.filter(
                mapFilterInitialized ? temp_arr : data,
                (j) => j.identifiers.includes(item.value)
              );
              if (index > 0) {
                // arr.push(...filteredData);
                arr = Array.from(new Set([...arr, ...filteredData].map(JSON.stringify)))
      .map(JSON.parse);
              } else {
             
                arr = [...filteredData];
                if (handleSelectBool) {
                  // debugger
                  handleSelectBool = false;
                }
          
              }
              filterInitialized = true;
            });
            temp_arr = [...arr];
          } else if (key == "users") {
            const mapFilterInitialized = filterInitialized;

            const filteredData = _.filter(
              mapFilterInitialized ? temp_arr : data,
              (j) =>
                j.users.some((user_item) =>
                  user_item?.email
                    ?.toLowerCase()
                    .includes(filArray.toLowerCase())
                )
            );

          
            arr = [...filteredData];
            if (handleSelectBool) {
              handleSelectBool = false;
            }
            filterInitialized = true;
            temp_arr = [...arr];
          } else if (key == "roles") {
            const mapFilterInitialized = filterInitialized;
            _.map(filArray, (item, index) => {
              //debugger;
              const filteredData = _.filter(
                mapFilterInitialized ? temp_arr : data,
                (j) =>
                  j.users.some((user_item) =>
                  Array.isArray(user_item?.roles) &&
                  user_item.roles.includes(item.value.toUpperCase())
                  )
              );
              //debugger
              if (index > 0) {
                // arr.push(...filteredData);
                arr = Array.from(new Set([...arr, ...filteredData].map(JSON.stringify)))
      .map(JSON.parse);
              } else {
               
                arr = [...filteredData];
                if (handleSelectBool) {
                  // debugger
                  handleSelectBool = false;
                }
               
              }
              filterInitialized = true;
            });
            temp_arr = [...arr];
            
          } else {
            const mapFilterInitialized = filterInitialized;
            _.map(filArray, (item, index) => {
              const filteredData = _.filter(
                mapFilterInitialized ? temp_arr : data,
                (j) => j[key] == item.value
              );
              if (index > 0) {
                // arr.push(...filteredData);
                arr = Array.from(new Set([...arr, ...filteredData].map(JSON.stringify)))
      .map(JSON.parse);
              } else {
                arr = [...filteredData];
                if (handleSelectBool) {
                  handleSelectBool = false;
                }
              }
              filterInitialized = true;
            });
            temp_arr = [...arr];
          }
         
        }
      } else {
      }
    });

    // setfiltered(arr.length ? arr : data);
    setfiltered(filterInitialized ? arr : data);

    // setHandleSelectBool(true)
    
  };

  const debouncedHandleSelect = debounce(handleSelect, 600);

  const columns = [
    {
      name: "data_catalog",
      label: "Database Name",
      width: 300,
      filter: false,
    },
    {
      name: "schema",
      label: "Schema Name",
      width: 300,
      filter: false,
    },
    {
      name: "table_name",
      label: "Table Name",
      width: 300,
      filter: false,
    },
    {
      name: "identifiers",
      label: "Identifiers",
      width: 300,
      options: {
        customBodyRender: (value) => {
          // Assuming value is an array of identifiers
          return value ? JSON.parse(value).join(", ") : "";
        },
      },
    },
    {
      name: "users",
      label: "Users Count",
      width: 300,
      options: {
        customBodyRender: (val) => {
          let userArr = userFilter(val);
          return Array.isArray(userArr) ? userArr.length : 0;
          // style: { textDecoration: "underline", fontSize: "large" },
        },
      },
    },
  ];

  const userFilter = (val) => {
    let modifiedVal =
      filter?.users != ""
        ? _.filter(val, (j) =>
            j?.email?.toLowerCase().includes(filter.users.toLowerCase())
          )
        : val;
      if (filter?.roles.length > 0) {
        modifiedVal = _.flatMap(filter.roles, (item) =>
          _.filter(modifiedVal, (j) => j?.roles.includes(item.value.toUpperCase()))
        );
      }
    return modifiedVal;
  };

  const options = {
    selectableRowsHideCheckboxes: true,
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    setRowProps: () => ({ className: classes.centeredrow }),
    selectableRows: "single",
    filterType: "multiselect",
    responsive: "scrollMaxHeight",
    rowsPerPage: 30,
    expandableRows: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    rowsPerPageOptions: [30, 60, 100],
    textLabels: {
      body: {
        noMatch: isLoading?"Loading the Data.....":"No data found"
      }
    },
    renderExpandableRow: (rowData, rowMeta) => {
      //DgPage(rowData[0].trim());
      //setRowsa(axiosexpands(rowData[0].trim()));
     
      return (
        <React.Fragment>
          <tr>
            <td colSpan={6}>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: "650" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" ><h6 className="secondaryTable">First Name</h6></TableCell>
                      <TableCell align="center" ><h6 className="secondaryTable">Last Name</h6></TableCell>
                      <TableCell align="center" ><h6 className="secondaryTable">Email</h6></TableCell>
                      <TableCell align="center" ><h6 className="secondaryTable">Roles</h6></TableCell>
                      {/* <TableCell align="center">TAG</TableCell>
                      <TableCell align="center">PROBABILITY</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userFilter(
                      Array.isArray(filtered[rowMeta["dataIndex"]].users) &&
                        filtered[rowMeta["dataIndex"]].users
                    ).map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="center" component="th" scope="row">
                          {row["first_name"]}
                        </TableCell>
                        <TableCell align="center">{row["last_name"]}</TableCell>
                        <TableCell align="center">
                          {/* {row["Email"]} */}
                          {row.email}
                        </TableCell>
                        <TableCell align="center">
                          {/* {row["Email"]} */}
                          {row.roles.join("\n")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    page: 1,
  };

  return (
    <div className="Outertext">
      <div>
        <img src={Background} alt="BG" />
      </div>
      <br></br>
      
      <div className="cf-mt mb-4 FormBox">
        <Form className="d-flex flex-wrap flex-sm-row hoverBorder project-user-form org">
          <Form.Group className="form-input filter-table-firstLayer">
            <label className="form-label form-label">Database Name</label>
            <Select
              styles={{
                control: () => ({
                  height: 33,
                  display: "flex",
                  borderRadius: "4px",
                  fontSize: 13,
                  border: "1px solid lightgray",
                  background: "white",
                }),
              }}
              className="dropdownSizeSummary outlined-select-currency"
              // defaultValue={}
              isMulti={true}
              onChange={(e) => handleSelect(e, 0, "data_catalog")}
              options={db}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </Form.Group>
          <Form.Group className="form-input filter-table-firstLayer">
            <label className="form-label">Schema Name</label>
            <Select
              styles={{
                control: () => ({
                  height: 33,
                  display: "flex",
                  borderRadius: "4px",
                  fontSize: 13,
                  border: "1px solid lightgray",
                  background: "white",
                }),
              }}
              className="dropdownSizeSummary outlined-select-currency"
              // defaultValue={}
              isMulti={true}
              onChange={(e) => handleSelect(e, 1, "schema")}
              options={schema}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </Form.Group>
          <Form.Group className="form-input filter-table-firstLayer">
            <label className="form-label form-label">Identifiers</label>
           
            <Select
              styles={{
                control: () => ({
                  height: 33,
                  display: "flex",
                  borderRadius: "4px",
                  fontSize: 13,
                  border: "1px solid lightgray",
                  background: "white",
                }),
              }}
              className="dropdownSizeSummary"
              // defaultValue={}
              isMulti={true}
              onChange={(e) => handleSelect(e, "iden", "identifiers")}
              options={identifiers}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </Form.Group>
          <Form.Group className="form-input filter-table-firstLayer">
            <label className="form-label form-label">Table Name</label>
            <Select
              styles={{
                control: () => ({
                  height: 33,
                  display: "flex",
                  borderRadius: "4px",
                  fontSize: 13,
                  border: "1px solid lightgray",
                  background: "white",
                }),
              }}
              className="dropdownSizeSummary outlined-select-currency"
              // defaultValue={}
              isMulti={true}
              onChange={(e) => handleSelect(e, 2, "table_name")}
              options={tables}
              // closeOnSelect={(e) => console.log(e, "close", )}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </Form.Group>
          <Form.Group className="form-input filter-table-secondLayer">
            <label className="form-label form-label">Roles</label>
            <Select
              styles={{
                control: () => ({
                  height: 33,
                  display: "flex",
                  borderRadius: "4px",
                  fontSize: 13,
                  border: "1px solid lightgray",
                  background: "white",
                }),
              }}
              className="dropdownSizeSummary outlined-select-currency"
              isMulti={true}
              onChange={(e) => handleSelect(e, 2, "roles")}
              options={roles}
              // closeOnSelect={(e) => console.log(e, "close", )}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </Form.Group>

          <Form.Group className="form-input filter-table-secondLayer">
            <label className="form-label form-label">User Email</label>

            <TextField
              placeholder="Enter user Email..."
              InputProps={{
                className: classes.customTextfield,
              }}
              onChange={(e) => debouncedHandleSelect(e, 0, "users")}
            />
          </Form.Group>
        </Form>
      </div>
      <div>
      
        <MUIDataTable
          columns={columns}
          data={filtered}
          options={options}
          className="table-access-review"
        />
      </div>
    </div>
  );
};
export default AccessReviewPage;