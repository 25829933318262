import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useEffect, useState } from "react";
import Background from "../assets/pcore_image.png";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import Typography from "@mui/material/Typography";
import { useOktaAuth } from "@okta/okta-react";
import moment from "moment";
import "./RumadminQuerieTable.css";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableTrim from "./TableTrim";
import { Card } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";
import CircularProgress from "@mui/material/CircularProgress";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function RumadminQuerieTable({ roleData, spinner }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState(null);

  const updatequery = (value) => {
    setQuery(value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(+event.target.value));
    setPage(0);
  };

  const color = {
    fontSize: "larger",
    fontWeight: "500",
    width: 70,
  };
  const colors = {
    color: "black",

    fontSize: "larger",
    fontWeight: "700",
    width: 70,
  };

  return (
    <React.Fragment>
      <div className="RumadminQuerieTable">
        <div className="Outerbox">
          <TableContainer
            component={Paper}
            style={{
              border: "solid",
              borderColor: "#ffff",
              borderWidth: "3px",
            }}
          >
            <Table sx={{ minWidth: 2094 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="right" style={colors}>
                    Execution Date
                  </StyledTableCell>
                  <StyledTableCell style={colors}>Query Text</StyledTableCell>
                  <StyledTableCell align="right" style={colors}>
                    Total Elapsed Time
                  </StyledTableCell>
                  <StyledTableCell align="right" style={colors}>
                    Role Name
                  </StyledTableCell>{" "}
                  <StyledTableCell align="right" style={colors}>
                    Database Name
                  </StyledTableCell>
                  <StyledTableCell align="right" style={colors}>
                    Schema Name
                  </StyledTableCell>
                  <StyledTableCell align="right" style={colors}>
                    Query Type
                  </StyledTableCell>
                  <StyledTableCell align="right" style={colors}>
                    Warehouse Name
                  </StyledTableCell>
                  <StyledTableCell align="right" style={colors}>
                    Execution Status
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roleData?.length ? (
                  roleData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow
                        hover={true}
                        key={row.queryText}
                        onClick={() => setQuery(row.queryText)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell
                          align="right"
                          size="small"
                          style={color}
                        >
                          {moment(row.startTime).format("YYYY-MMM-DD")}
                        </StyledTableCell>
                        <TableTrim
                          updatequery={updatequery}
                          style={color}
                          text={row.queryText}
                        />
                        <StyledTableCell
                          align="right"
                          size="small"
                          style={color}
                        >
                          {row.totalElapsedTime}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          size="small"
                          style={color}
                        >
                          {row.roleName}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          size="small"
                          style={color}
                        >
                          {row.databaseName}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          size="small"
                          style={color}
                        >
                          {row.schemaName}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          size="small"
                          style={color}
                        >
                          {row.queryType}
                        </StyledTableCell>

                        <StyledTableCell
                          align="right"
                          size="small"
                          style={color}
                        >
                          {row.warehouseName}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          size="small"
                          style={color}
                        >
                          {row.executionStatus}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    {/* <TableCell></TableCell>
                    <TableCell></TableCell> */}
                    <TableCell
                      className="novalue"
                      style={{
                        color: "rgb(225, 138, 24)",
                        fontSize: "large",
                        fontWeight: "700",
                      }}
                      align="right"
                    >
                      {(spinner && <CircularProgress color="inherit" />) ||
                        "No Queries Found."}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 40]}
              count={roleData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>

        <div className="Outerboxs">
          <Typography gutterBottom variant="h5" className="Innerboxs">
            Query Text
          </Typography>
          <Card>
            <Typography className="Innerbox">{query && query}</Typography>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}
