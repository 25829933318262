// ===============================Table=================================

import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Background from "../assets/pcore_image.png";
import "./PersonaCatalog.css";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import axios from "axios";
import { MenuItem } from "@mui/material";
import Popper from "@material-ui/core/Popper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const styles = (theme) => ({
  popper: {
    width: "fit-content",
  },
});
export default function UserTables({ props }) {
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;
  const Url = process.env.BASE_URL;

 

  const [value, setValue] = React.useState(props);
  const [role, setRole] = useState([]);
  var roleData = [];

  const [userList, setUserList] = useState(null);
  const [loading, setLoading] = useState(null);

  const PopperMy = function (props) {
    return <Popper {...props} style={styles.popper} placement="bottom-start" />;
  };

 
  const userListFn = async () => {
    setLoading("Loading....");
    await axios
      .get(Url + `get-persona-members?idPersona=${props}`, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        var array = [];
        const datas = data && data.data.personaMembers;
        array.push(datas);
        setUserList(datas);
        setLoading(null);
      })
      .catch((err) => {
        setLoading(null);
        console.log(err);
      });
  };

  useEffect(() => {
    if (props) {
      setUserList(null);
      userListFn();
    }
  }, [props]);
  useEffect(() => {
    axios
      .get(Url + "get-persona-groups", {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        var obj = {};
        var array = [];
        data.data.personaGroups.map((item, i) => {
          obj = { label: item.personaName, id: item.personaId };
          array.push(obj);
        });
        setRole(array);
      })

      .catch((err) => console.log(err));
  }, []);

  if (typeof role == "string") {
    roleData.push(role);
  } else {
    role &&
      role.map((data) => {
        roleData.push(data);
      });
  }

  const design = {
    border: "solid",
    borderColor: "#ffff",
    borderWidth: "3px",
  };
  const colors = {
    color: "black",
    fontSize: "larger",
    fontWeight: "700",
  };
  const color = {
    verticalAlign: "baseline",
  };
  return (
    <div className="personaPage" >
      <div className="tabelParent" >
        <TableContainer component={Paper} style={design}>
          <Table sx={{ minWidth: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={colors}>User Name</StyledTableCell>
                <StyledTableCell style={colors}>Email</StyledTableCell>
                <StyledTableCell style={colors}>Manager</StyledTableCell>
                <StyledTableCell style={colors}>Department</StyledTableCell>
                <StyledTableCell style={colors}>Assigned date</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList?.length ? (
                userList.map((row) => (
                  <StyledTableRow>
                    <StyledTableCell style={color}>{row.name}</StyledTableCell>
                    <StyledTableCell style={color}>{row.email}</StyledTableCell>
                    <StyledTableCell style={color}>
                      {row.managerName}
                    </StyledTableCell>
                    <StyledTableCell style={color}></StyledTableCell>
                    <StyledTableCell style={color}></StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell
                    className="novalue"
                    style={{
                      color: "rgb(225, 138, 24)",
                      fontSize: "large",
                      fontWeight: "700",
                      textAlign: "start",
                    }}
                    align="right"
                  >
                    {loading || "No Data Found"}
                  </StyledTableCell>

                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
