import * as React from "react";
import { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./landingpage.css";
import axios from "axios";
import "react-tabs/style/react-tabs.css";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "@mui/material/Button";
import MUIDataTable from "mui-datatables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core/styles";
import { useOktaAuth } from "@okta/okta-react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Optional from "./Optional";
import Soon from "../assets/soon.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

export default function PolicyComp() {
  const Url = process.env.BASE_URL;
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;
  const cond = [
    { label: "Snowflake Roles", value: "user_role" },
    { label: "Snowflake Users", value: "user_email" },
  ];
  const format = {
    policy_name: "",
    policy_type: "dynamic_data_masking",
    masking_policy_type: "dataset_based_masking",
    description: "",
    policy_condition: "",
    exception_list: [],
    masked_value_display: "",
    dataset_name: "",
    tag_name: "",
    identifier_list: [],
    and_or: "",
    attribute: "",
    condition: "",
    user_text: "",
    column_name: "",
    column_value: "",
  };
  // const [masked, setMasked] = useState(true);
  const [tab, setTab] = useState(true);
  const [savePolicy, setSavePolicy] = useState(format);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [edit, setEdit] = useState(false);
  const [enableRoles, setEnableRoles] = useState(false);
  const [activeCond, setActiveCond] = useState([]);
  const [tagNames, setTagNames] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [exceptionValue, setExceptionValue] = useState("Roles");
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [update, setUpdate] = useState(false);
  const [viewPolicy, setViewPolicy] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [showView, setShowView] = useState(false);
  const [view, setView] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const [nameWarning, setNameWarning] = useState(false);
  const [customModal, setCustomModal] = useState(false);

  const useStyles = makeStyles((theme) => ({
    centeredHeader: {
      "& span": {
        display: "flex",

        justifyContent: "center",

        alignItems: "center",

        fontSize: "15px",

        fontWeight: "600",
      },
    },

    centeredrow: {
      "& span": {
        display: "flex",

        justifyContent: "center",

        alignItems: "center",

        fontSize: "15px",

        fontWeight: "400",
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    axios
      .get(Url + "get-dpds-snowflake-roles", {
        // .get(Url + "get-personas", {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        let respData = data.data;
        // const data1 =
        // data.data.personas !== null
        // ? data.data.personas
        // : "Currently No Roles Assigned.";
        let arr = [];
        // _.map(data.data.personas, (i) => {
        // _.includes(i.name, "Snowflake") ? arr.push(i.name) : "";
        // });
        if (respData?.status == "success") {
          arr = respData.role_list;
        }
        setRoles(getSelectVal(arr));
      })
      .catch((err) => console.log(err));
  }, []);

  const getAllPoicies = () => {
    setIsLoading(true);
    axios
      .get(Url + "get-dpds-view-policies", {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        // console.log(data.data, "getPolicy");
        setIsLoading(false);
        setViewPolicy(data.data.policy_list);
        if (data.data.status == "fail") {
          toast.error(data.data?.msg);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getAllPoicies();
  }, []);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    axios
      .get(Url + `get-dpds-view-global-tags`, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        let name = [];
        _.map(data.data.global_tags, (i) => {
          name.push(i.global_tag_name);
        });
        // setAllData(data.data.global_tags);
        setTagNames(getSelectVal(name));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(Url + `get-dpds-view-datasets`, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        let database = [];
        if (data.data) {
          // setViewDataset(data.data);
          // data.data
          let arr = [];
          _.map(data.data.datasets, (i) => {
            arr.push(i[0]);
          });
          setDataset(getSelectVal(arr));
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(Url + "get-snowflake-users", {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        var obj = {};
        var array = [];
        data.data.snowflakeUsers.map((item, i) => {
          // obj = { label: item.user, id: item.user };
          array.push(item.user);
        });
        // (array);
        setUsers(getSelectVal(array));
      })
      .catch((err) => console.log(err));
  }, []);

  const convertStrSelect = (str) => {
    let arr = [];
    if (str == "") {
      return { label: "", value: "" };
    } else {
      arr.push(str);
      return getSelectVal(arr);
    }
  };

  const getSelectVal = (arr) => {
    let temp = [];
    _.map(arr, (i) => {
      let obj = {};
      obj.label = i;
      obj.value = i;
      temp.push(obj);
    });
    return temp;
  };

  const handleTab = (e, type) => {
    let temp = _.cloneDeep(savePolicy);
    if (type == "row") {
      // temp.masking_policy_type = "";
      // temp.masked_value_display=''
      setTab(false);
      // setMasked(false);
    } else {
      // temp.masking_policy_type = format.masking_policy_type;
      // temp.column_name = "";
      // temp.column_value = "";
      setTab(true);
    }
    // setMasked(true);
    temp["policy_type"] = e.target.value;
    setSavePolicy(temp);
  };

  const handleRadio = (e, type) => {
    let temp = _.cloneDeep(savePolicy);
    // type == "tag_based_masking" ? temp.masked_value_display='' : null;
    temp["masking_policy_type"] = type;
    setSavePolicy(temp);
  };

  const handleCond = (val) => {
    setEnableRoles(true);
    if (val == "user_role") {
      setExceptionValue("Roles");
      setActiveCond(roles);
    } else {
      setExceptionValue("Users");
      setActiveCond(users);
    }
  };

  useEffect(() => {
    handleCond(savePolicy.policy_condition);
  }, [savePolicy.policy_condition]);

  const handleCreate = (e) => {
    debugger;
    let temp = _.cloneDeep(savePolicy);
    // debugger;
    if (temp.and_or != "") {
      if (
        [temp.attribute, temp.condition, temp.user_text].some((x) => x == "")
      ) {
        setCustomModal(true);
        // showConfirm('true')
        return;
      }
    }
    if (temp.policy_type == "row_access_policy") {
      // temp.masking_policy_type = "";
      temp.masked_value_display = "";
    } else if (temp.masking_policy_type == "tag_based_masking") {
      temp.masked_value_display = "";

      temp.column_name = "";
      temp.column_value = "";
    } else {
      temp.column_name = "";
      temp.column_value = "";
    }
    temp.okta_email = userInfo.email;

    axios
      .post(Url + `dpds-create-policy`, temp, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        toast(_.capitalize(data.data.msg));
        if (data.data.status != "fail") {
          getAllPoicies(); // Getting All policies
          setTabIndex(0);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleName = (e, type) => {
    let temp = _.cloneDeep(savePolicy);
    let value = e.target.value;

    if (type == "policy_name") {
      if (value.includes(" ")) {
        setNameWarning(true);
      } else {
        setNameWarning(false);
      }
      temp[type] = value.replace(/\s/g, "");
    } else {
      setNameWarning(false);

      temp[type] = value;
    }
    setSavePolicy(temp);
  };

  const handleSelect = (e, type) => {
    let temp = _.cloneDeep(savePolicy);
    if (type == "exception_list") {
      let arr = [];
      _.map(e, (i) => {
        arr.push(i.value);
      });
      temp[type] = arr;
    } else {
      temp[type] = e.value;
    }
    setSavePolicy(temp);
  };

  const handleOptional = (e, type) => {
    let temp = _.cloneDeep(savePolicy),
      optional = ["and_or", "attribute", "condition"];

    if (e.length && !_.includes(optional, type)) {
      temp[type] = [];
      _.map(e, (i) => {
        if (!_.includes(temp[type], i.value)) {
          temp[type].push(i.value);
        }

        temp[type] = _.uniq(temp[type]);
      });
    } else if (type == "user_text") {
      temp[type] = e.target.value;
    } else {
      temp[type] = e.value;
    }
    setSavePolicy(temp);
    setUpdate(!update);
  };

  const getPolicyDetail = async () => {
    let id = toast.loading("Please wait, Fetching data...");
    await axios
      .get(Url + `get-dpds-load-policy?policy-name=${selectedRow[0]}`, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        toast.dismiss(id);
        toast.success("Data loaded successfully.", { autoClose: 750 });
        let database = [];

        if (data.data) {
          let res = data.data;
          setSavePolicy(res);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(id);
        toast.error("Data loading failed.", { autoClose: 1500 });
      });
  };
  const showConfirm = (par) => {
    if (par == "reset") {
      setSavePolicy({ ...savePolicy, and_or: "" });
    }
    setCustomModal(!customModal);
  };
  const columns = [
    {
      name: "Policy Name",
      width: 300,
      filter: false,
    },
    {
      name: "Policy Type",
      width: 300,
      filter: false,
    },
    {
      name: "Masking Type",
      width: 300,
      filter: false,
    },
    {
      name: "Description",
      width: 300,
      filter: false,
    },
    {
      name: "Created By",
      width: 300,
      filter: false,
    },
    {
      name: "Dataset Name",
      width: 300,
      filter: false,
    },
    {
      name: "Tag Name",
      width: 300,
      filter: false,
    },
  ];

  const options = {
    selectableRowsHideCheckboxes: false,
    // onFilterChange: (changedColumn, filterList) => {
    // console.log(changedColumn, filterList);
    // },
    onRowSelectionChange: (dataIndex, selectedRows, arr) => {
      if (arr.length != 0) {
        setSelectedRow(viewPolicy[arr[0]]);
        console.log(viewPolicy[arr[0]], "lllll");
        // // setEditData(getTags[arr[0]])
        // let obj = {
        // policy_name: viewPolicy[arr[0]][0],
        // policy_type: viewPolicy[arr[0]][1],
        // masking_policy_type: viewPolicy[arr[0]][2],
        // description: viewPolicy[arr[0]][3],
        // policy_condition: "",
        // exception_list: [],
        // masked_value_display: "",
        // dataset_name: viewPolicy[arr[0]][5],
        // tag_name: viewPolicy[arr[0]][6],
        // identifier_list: [],
        // and_or: "",
        // attribute: "",
        // condition: "",
        // user_text: "",
        // };
        // console.log(getTags[arr[0][5]], _.includes(getTags[arr[0][5]], "%"), "_.includes(getTags[arr[0][5]], "%")")
        // if(_.includes(getTags[arr[0][5]], "%")){
        // setTagInput(getTags[arr[0][5]])
        // }
        // obj.is_table_level_tag == true ? setLevel(true) : setLevel(false);
        // console.log(obj, "arrrrr");
        // setOptionalInput(obj.user_text);
        // setTagInput(obj.tag_user_input_value);
        // setSaveTag(obj);
        // setSavePolicy(obj)
        // setPolicyName(getTags[arr[0]][0])
        // setPolicyDesc(getTags[arr[0]][1])
        setShowEdit(true);
      } else {
        setShowEdit(false);
      }
    },
    customToolbarSelect: () => {},
    setRowProps: () => ({ className: classes.centeredrow }),
    selectableRows: "single",
    filterType: "multiselect",
    responsive: "scrollMaxHeight",
    tableBodyHeight: "60%",
    rowsPerPage: 30,
    download: false,
    filter: false,
    print: false,
    search: true,
    viewColumns: false,
    rowsPerPageOptions: [30, 60, 100],
    page: 0,
    textLabels: {
      body: {
        noMatch: isLoading ? "Loading the Data....." : "No data found",
      },
    },
  };

  const handleEdit = (e, type) => {
    getPolicyDetail();
    if (type == "view") {
      setView(true);
    } else {
      setEdit(true);
    }
    setTabIndex(1);
  };

  const handleCancel = (e) => {
    setSavePolicy(format);
    setShowEdit(false);
    setView(false);
    setEdit(false);
    // setMasked(true);
    setTab(true);
  };

  return (
    <>
      <div className="Outertext">
        <div className="dataset-cover">
          <div className="heading-dataset">
            <h4 className="dataset-head">Policy Management</h4>
          </div>
          <div className="tabs-cover">
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(tabIndex) => setTabIndex(tabIndex)}
            >
              <TabList>
                <Tab
                  onClick={(e) => {
                    setView(false), handleCancel(e);
                  }}
                >
                  View Policies
                </Tab>
                <Tab onClick={(e) => handleCancel(e)}>{`${
                  view ? "View" : edit ? "Edit" : "Create"
                } Policy`}</Tab>
              </TabList>
              <TabPanel>
                <div>
                  <MUIDataTable
                    columns={columns}
                    data={viewPolicy}
                    options={options}
                  />
                </div>
                <div>
                  <Button
                    className="dataset-button"
                    variant="contained"
                    size="large"
                    disabled={!showEdit}
                    onClick={(e) => {
                      handleEdit(e, "edit");
                    }}
                  >
                    Edit Policy
                  </Button>
                  <Button
                    className="dataset-button"
                    variant="contained"
                    size="large"
                    onClick={(e) => {
                      setEdit(false), setTabIndex(1);
                    }}
                  >
                    Create Policy
                  </Button>
                  <Button
                    className="dataset-button"
                    variant="contained"
                    size="large"
                    disabled={!showEdit}
                    onClick={(e) => {
                      handleEdit(e, "view");
                    }}
                  >
                    View Policy
                  </Button>
                </div>
              </TabPanel>
              <TabPanel>
                {/* <div className="dataset-heading">
 <h4 className="tag-head">Tag Management</h4>
 </div> */}
                <div
                  className="policy-radio-tag label-radio-top"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {/* <div className="label-text select-in">
 <label>Apply Tag at :</label>
 </div> */}
                  <div style={{ display: "flex", width: "300px" }}>
                    <input
                      type="radio"
                      id="dynamic_data_masking"
                      name="tab"
                      value="dynamic_data_masking"
                      checked={savePolicy.policy_type == "dynamic_data_masking"}
                      onChange={(e) => handleTab(e, "mask")}
                      disabled={view}
                    />
                    <label
                      className="label-policy"
                      htmlFor="dynamic_data_masking"
                    >
                      Dynamic Data Masking Policy
                    </label>
                  </div>
                  <div style={{ display: "flex", width: "200px" }}>
                    <input
                      type="radio"
                      id="row_access_policy"
                      name="tab"
                      value="row_access_policy"
                      checked={savePolicy.policy_type == "row_access_policy"}
                      // checked={!tab}
                      disabled={view}
                      onChange={(e) => handleTab(e, "row")}
                    />
                    <label className="label-policy" htmlFor="row_access_policy">
                      Row Access Policy
                    </label>
                  </div>
                </div>
                <div className="policy-cover">
                  {tab && (
                    <div
                      className="policy-radio-tag label-radio-policy"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {/* <div className="label-text select-in">
 <label>Apply Tag at :</label>
 </div> */}
                      <div style={{ display: "flex", width: "200px" }}>
                        <input
                          type="radio"
                          id="dataset_based_masking"
                          name="Dataset Based Masking"
                          value="dataset_based_masking"
                          // value={savePolicy.masking_policy_type}
                          checked={
                            savePolicy.masking_policy_type ==
                            "dataset_based_masking"
                          }
                          onChange={(e) =>
                            handleRadio(e, "dataset_based_masking")
                          }
                          disabled={view}
                        />
                        <label
                          className="label-policy"
                          htmlFor="dataset_based_masking"
                        >
                          Dataset Based Masking
                        </label>
                      </div>
                      {console.log(savePolicy, "savepo")}
                      <div style={{ display: "flex", width: "200px" }}>
                        <input
                          type="radio"
                          id="tag_based_masking"
                          name="Tag Based Masking"
                          value="tag_based_masking"
                          // value={savePolicy.masking_policy_type}
                          checked={
                            savePolicy.masking_policy_type ==
                            "tag_based_masking"
                          }
                          onChange={(e) => handleRadio(e, "tag_based_masking")}
                          disabled={view}
                        />
                        <label
                          className="label-policy"
                          htmlFor="tag_based_masking"
                        >
                          Tag Based Masking
                        </label>
                      </div>
                    </div>
                  )}
                  <div
                    className={tab ? "tags-cover border-policy" : "tags-cover"}
                  >
                    <div className="policy-label">
                      {/* <div className="label-text select-policy"> */}
                      <label>Policy Name :</label>
                    </div>
                    <div className="select-tags policy-value">
                      <input
                        placeholder="Enter Policy Name"
                        type="text"
                        className="form-control "
                        value={savePolicy.policy_name}
                        // disabled={selectedTag.allowed_values[0] != null}
                        id="usr"
                        disabled={view}
                        onChange={(e) => handleName(e, "policy_name")}
                      />
                      {nameWarning && (
                        <h6
                          style={{
                            color: "orangered",
                            fontSize: "600",
                            margin: "5px 5px 0",
                            display: "flex",
                          }}
                        >
                          Space character not allowed!
                        </h6>
                      )}
                    </div>
                  </div>
                  <div className="tags-cover">
                    <div className="policy-label">
                      {/* <div className="label-text select-desc"> */}
                      <label>Policy Description :</label>
                    </div>
                    <div className="select-tags policy-value">
                      <input
                        placeholder="Enter Policy Description"
                        type="text"
                        className="form-control "
                        value={savePolicy.description}
                        // disabled={selectedTag.allowed_values[0] != null}
                        id="usr"
                        disabled={view}
                        onChange={(e) => handleName(e, "description")}
                      />
                    </div>
                  </div>
                  <div className="tags-cover">
                    <div className="policy-label">
                      {/* <div className="label-text select-cond"> */}
                      <label>Condition Based On :</label>
                    </div>
                    <div className="select-tags">
                      <Form.Group className="form-input col-sm">
                        {/* <label className="form-label form-label">Identifiers</label> */}
                        <Select
                          styles={{
                            control: () => ({
                              height: 33,
                              display: "flex",
                              borderRadius: "4px",
                              fontSize: 13,
                              border: "1px solid lightgray",
                              background: "white",
                            }),
                          }}
                          className="dropdownSizeSummary"
                          // defaultValue={}
                          isMulti={false}
                          onChange={(e) => {
                            handleCond(e.value),
                              handleSelect(e, "policy_condition");
                          }}
                          // isDisabled={selectedTag.allowed_values[0] == null}
                          value={cond.filter(
                            (option) =>
                              option.value === savePolicy.policy_condition
                          )}
                          isDisabled={view}
                          options={cond}
                          placeholder="Select Condition"
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="tags-cover">
                    <div className="policy-label">
                      {/* <div className="label-text select-roles"> */}
                      <label>Exception {exceptionValue} :</label>
                    </div>
                    <div className="select-tags">
                      <Form.Group className="form-input col-sm">
                        {/* <label className="form-label form-label">Identifiers</label> */}
                        <Select
                          styles={{
                            control: () => ({
                              height: 33,
                              display: "flex",
                              borderRadius: "4px",
                              fontSize: 13,
                              border: "1px solid lightgray",
                              background: "white",
                            }),
                          }}
                          className="dropdownSizeSummary"
                          // defaultValue={}
                          isMulti={true}
                          onChange={(e) => handleSelect(e, "exception_list")}
                          isDisabled={!enableRoles || view}
                          value={
                            Array.isArray(savePolicy.exception_list)
                              ? getSelectVal(savePolicy.exception_list)
                              : null
                          }
                          options={activeCond}
                          placeholder="Select Exception"
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  {
                    savePolicy.policy_type == "dynamic_data_masking" && (
                      <div className="tags-cover">
                        <div className="policy-label">
                          {/* <div className="label-text select-mask"> */}
                          <label>Display Mask Value As:</label>
                        </div>
                        <div className="select-tags">
                          <input
                            placeholder="Enter Mask Value"
                            type="text"
                            className="form-control "
                            value={savePolicy.masked_value_display}
                            // disabled={selectedTag.allowed_values[0] != null}
                            disabled={view}
                            id="usr"
                            onChange={(e) =>
                              handleName(e, "masked_value_display")
                            }
                          />
                        </div>
                      </div>
                    )}
                  {savePolicy.policy_type == "row_access_policy" && (
                    <>
                      <div className="tags-cover">
                        <div className="policy-label">
                          {/* <div className="label-text select-mask"> */}
                          <label>Policy Column Name:</label>
                        </div>
                        <div className="select-tags">
                          <input
                            placeholder="Enter Column Name"
                            type="text"
                            className="form-control"
                            value={savePolicy.column_name}
                            // disabled={selectedTag.allowed_values[0] != null}
                            disabled={view}
                            id="usr"
                            onChange={(e) => handleName(e, "column_name")}
                          />
                        </div>
                      </div>
                      <div className="tags-cover">
                        <div className="policy-label">
                          {/* <div className="label-text select-mask"> */}
                          <label>Policy Column Value:</label>
                        </div>
                        <div className="select-tags">
                          <input
                            placeholder="EnterColumn Value"
                            type="text"
                            className="form-control "
                            value={savePolicy.column_value}
                            // disabled={selectedTag.allowed_values[0] != null}
                            disabled={view}
                            id="usr"
                            onChange={(e) => handleName(e, "column_value")}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {(savePolicy.masking_policy_type != "tag_based_masking" || savePolicy.policy_type == "row_access_policy")&& (
                    <div className="tags-cover">
                      <div className="policy-label">
                        {/* <div className="label-text select-dataset"> */}
                        <label>Data Set Name:</label>
                      </div>
                      <div className="select-tags">
                        <Form.Group className="form-input col-sm">
                          {/* <label className="form-label form-label">Identifiers</label> */}
                          <Select
                            styles={{
                              control: () => ({
                                height: 33,
                                display: "flex",
                                borderRadius: "4px",
                                fontSize: 13,
                                border: "1px solid lightgray",
                                background: "white",
                              }),
                            }}
                            className="dropdownSizeSummary"
                            // defaultValue={}
                            isMulti={false}
                            onChange={(e) => handleSelect(e, "dataset_name")}
                            // isDisabled={selectedTag.allowed_values[0] == null}
                            value={
                              savePolicy.dataset_name
                                ? convertStrSelect(savePolicy.dataset_name)
                                : null
                            }
                            options={dataset}
                            isDisabled={view}
                            placeholder="Select Data Set"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  )}
                  {savePolicy.policy_type == "dynamic_data_masking" && savePolicy.masking_policy_type == "tag_based_masking" && (
                    <div className="tags-cover">
                      <div className="policy-label">
                        {/* <div className="label-text select-tagName"> */}
                        <label>Tags Name:</label>
                      </div>
                      <div className="select-tags">
                        <Form.Group className="form-input col-sm">
                          {/* <label className="form-label form-label">Identifiers</label> */}
                          <Select
                            styles={{
                              control: () => ({
                                height: 33,
                                display: "flex",
                                borderRadius: "4px",
                                fontSize: 13,
                                border: "1px solid lightgray",
                                background: "white",
                              }),
                            }}
                            className="dropdownSizeSummary"
                            // defaultValue={}
                            // isMulti={true}
                            onChange={(e) => handleSelect(e, "tag_name")}
                            // isDisabled={selectedTag.allowed_values[0] == null}
                            value={convertStrSelect(savePolicy.tag_name)}
                            isDisabled={view}
                            options={tagNames}
                            placeholder="Select Tag"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  )}
                  {(savePolicy.masking_policy_type != "tag_based_masking"||savePolicy.policy_type == "row_access_policy") && (
                    // <Optional show={savePolicy.and_or == "" ? false : true} handleOptional = {handleOptional} />
                    <Optional
                      // show={savePolicy.and_or == "" ? false : true}
                      editDisable={view}
                      handleOptional={handleOptional}
                      inputValues={savePolicy}
                      convertStrSelect={convertStrSelect}
                    />
                  )}
                  <div className="button-cover">
                    {!view && (
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={(e) => handleCreate(e)}
                      >
                        {`${edit ? "Update" : "Create"} Policy`}
                      </button>
                    )}
                    {!view && !edit && (
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={(e) => {
                          handleCancel(e);
                        }}
                      >
                        Reset
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={(e) => {
                        handleCancel(e);
                        setTabIndex(0);
                      }}
                    >
                      {view && <ArrowBackOutlinedIcon />}{" "}
                      {view ? "Back" : "Cancel"}
                    </button>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
            {customModal && (
              <Dialog
                open={customModal}
                onClose={() => setCustomModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle style={{ color: "black", fontSize: "16px" }}>
                  {'Please complete additional condition based on "AND/OR".'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    - Click "Continue" to fill in required details.
                    <br />- Click "Reset" to clear the "AND/OR" selection.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => showConfirm("reset")}>Reset</Button>
                  <Button onClick={() => showConfirm("continue")} autoFocus>
                    Continue
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </div>
        </div>
      </div>
    </>
  );
}