import * as React from "react";
import { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./landingpage.css";
import axios from "axios";
import "react-tabs/style/react-tabs.css";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "@mui/material/Button";

export default function Optional({inputValues,handleOptional,convertStrSelect,editDisable}) {

	const Url = process.env.BASE_URL;
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;
	const [identifiers, setIdentifiers] = useState([]);

  const defaultVal = {
    and_or: [
      { label: "AND", value: "AND" },
      { label: "OR", value: "OR" },
    ],
    attribute: [
      { label: "Database Name", value: "table_catalog" },
      { label: "Schema Name", value: "table_schema" },
      { label: "Table Name", value: "table_name" },
      { label: "Column Name", value: "column_name" },
    ],
    condition: [
      { label: "Equals", value: "Equals" },
      { label: "Like", value: "Like" },
      { label: "In", value: "In" },
    ],
  };

  const getIdentifiers = (data) => {
    let temp = [];
    _.map(data, (i, index) => {
      _.map(i.identifiers, (j) => {
        if (!_.includes(temp, j)) {
          temp.push(j);
        }
      });
    });
    setIdentifiers(getSelectVal(_.uniq(temp)));
  };

	const getSelectVal = (arr) => {
    let temp = [];
    _.map(arr, (i) => {
      let obj = {};
      obj.label = i;
      obj.value = i;
      temp.push(obj);
    });
    return temp;
  };

	useEffect(() => {
    axios
      .get(Url + `get-pii-data`, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        const data1 =
          data.data["data_classification"] !== null
            ? data.data["data_classification"]
            : "Currently No Identifers.";
        getIdentifiers(data1);
				console.log(data1,"data1data1")
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="row">
      <div className="col-md-10 condition-row">
        <div className="condition-inner">
          <div className="condition-cover">
            <div className="label-text select-iden">
              <label>Identifer :</label>
            </div>
            <div className="select-identifier">
              <Form.Group className="form-input col-sm">
                {/* <label className="form-label form-label">Identifiers</label> */}
                <Select
                  styles={{
                    control: () => ({
                      height: 33,
                      display: "flex",
                      borderRadius: "4px",
                      fontSize: 13,
                      border: "1px solid lightgray",
                      background: "white",
                    }),
                  }}
                  className="dropdownSizeSummary"
                  // defaultValue={}
                  isMulti={true}
                    onChange={(e) => handleOptional(e, "identifier_list")}
                  isDisabled={editDisable}
                    value={getSelectVal(
                     inputValues.identifier_list
                    )}
                    options={identifiers}
                  placeholder="rule/identity"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </Form.Group>
            </div>
          </div>
          <div className="condition-end">
            <div className="label-text select-and_or">
              <label>AND/OR :</label>
            </div>
            <div className="select-identifier and_right">
              <Form.Group className="form-input col-sm">
                {/* <label className="form-label form-label">Identifiers</label> */}
                <Select
                  styles={{
                    control: () => ({
                      height: 33,
                      display: "flex",
                      borderRadius: "4px",
                      fontSize: 13,
                      border: "1px solid lightgray",
                      background: "white",
                    }),
                  }}
                  className="dropdownSizeSummary"
                  isDisabled={editDisable}
                  // defaultValue={}
                    onChange={(e) => handleOptional(e, "and_or")}
                  options={defaultVal.and_or}
                  value={convertStrSelect(inputValues.and_or)}
                  placeholder="AND/OR"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="border-cover">
          <div className="condition-inner">
            <div className="condition-cover">
              <div className="label-text select-attri">
                <label>Attribute :</label>
              </div>
              <div className="select-identifier">
                <Form.Group className="form-input col-sm">
                  {/* <label className="form-label form-label">Identifiers</label> */}
                  <Select
                    styles={{
                      control: () => ({
                        height: 33,
                        display: "flex",
                        borderRadius: "4px",
                        fontSize: 13,
                        border: "1px solid lightgray",
                        background: inputValues.and_or == "" ? "#e9ecef" : "white",
                      }),
                    }}
                    className="dropdownSizeSummary"
                    onChange={(e) => handleOptional(e, "attribute")}
                    isDisabled={editDisable}
                    options={defaultVal.attribute}
                    value={convertStrSelect(inputValues.attribute)}
                    placeholder="attribute"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="condition-end">
              <div className="label-text select-condi">
                <label>Condition : </label>
              </div>
              <div className="select-identifier">
                <Form.Group className="form-input col-sm">
                  {/* <label className="form-label form-label">Identifiers</label> */}
                  <Select
                    styles={{
                      control: () => ({
                        height: 33,
                        display: "flex",
                        borderRadius: "4px",
                        fontSize: 13,
                        border: "1px solid lightgray",
                        background: inputValues.and_or == "" ? "#e9ecef" : "white",
                      }),
                    }}
                    className="dropdownSizeSummary"
                    onChange={(e) => handleOptional(e, "condition")}
                    isDisabled={editDisable}
                    options={defaultVal.condition}
                    value={convertStrSelect(inputValues.condition)}
                    placeholder="condition"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="condition-inner">
            <div className="condition-cover">
              <div className="label-text select-val">
                <label>Value :</label>
              </div>
              <div className="select-identifier">
                <input
                  placeholder="Eg.%COMPANY%"
                  type="text"
                  className="form-control "
                  value={inputValues.user_text}
                    disabled={inputValues.and_or == ""||editDisable}
                  id="usr"
                    onChange={(e) => handleOptional(e, "user_text")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-md-2 add-cover">
                  <div className="button-add">
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={(e) => handleAdd(e)}
                    >
                      Add
                    </button>
                  </div>
                </div> */}
    </div>
  );
}