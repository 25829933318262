import * as React from "react";
import TableauReport from 'tableau-react';

import "./My_Queries.css";

import Soon from "../assets/soon.png";
export default function BasicTable() {
  const options = {
    hideTabs: true,    
    hideToolbar: true, height: 1000,
    width: '100%'

  };
  return (
    <>
      <div className="Outerbox">
      <TableauReport
       url="https://data.procore-it.com/views/SnowflakeAuditDashboard_17002431190520/SnowflakeAuditDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link"
    /*url="https://public.tableau.com/views/Expense_dash/Dashboard1?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link&:size=1100x700"*/
   options={options} 
  />
        {/* <img style={{ width: "100%" }} src={Soon}></img> */}
      </div>
    </>
  );
}
