import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  MenuItem,
  InputLabel,
  Select,
  CardActions,
  Button,
  CardHeader,
  FormControl,
} from "@material-ui/core";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { Divider } from "@mui/material";
import { width } from "@mui/system";
import Container from "@mui/material/Container";
import { useParams, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Personamodal from "./Createpersonamodel";
import Background from "../assets/pcore_image.png";
import { useHistory } from "react-router-dom";

import "./CreatePersona.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const useStyle = makeStyles(theme => ({
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));


const CreatePersona = () => {
  const classes = useStyle();
  const { type } = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [personName, setPersonName] = React.useState([]);
  const [age, setAge] = React.useState("");
  const [collections, setCollections] = useState([]);
  const [editData, setEditData] = useState({});
  const history = useHistory();




  const addToCollections = data => {
    setCollections(prev => [...prev, data]);
  };

  const handleChanges = event => {
    const {
      target: { value },
    } = event;
    let obj =
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value;
    setPersonName(obj);
  };
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = data => {
    const allData = { ...data, collections };
  };

  const onEdit = (id, data) => {
    const newData = collections.filter(item);
  };

  return (
    <>
      <div className="Outertext">
        <div>
          <img style={{ width: "100%" }} src={Background} alt="BG" />
        </div>
        <div style={{ marginTop: "12px" }}>
          <Container style={{}}>
            <Grid container>
              <Grid item md={12}>
                <Card className={classes.padding}>
                  <CardHeader
                    title={`${
                      type === "edit" ? "Edit" : "Create New"
                    }  Persona`}></CardHeader>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                      <Grid item container spacing={2} justify="center">
                        <Grid item xs={12} sm={12} md={12}>
                          <div className="labelEdit">Persona Name</div>

                          <Controller
                            name="Persona_Name"
                            rules={{ required: true }}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                id="Persona_Name"
                                value={editData.personaName}
                              />
                            )}
                          />
                          {errors.Persona_Name && (
                            <span style={{ color: "red", display: "flex" }}>
                              *Name is required
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          {" "}
                          <div className="labelEdit">Persona Description</div>
                          <Controller
                            name="Persona_description"
                            rules={{ required: true }}
                            control={control}
                            render={({ field }) => (

                              <TextField
                                id="outlined-multiline-static"
                                {...field}
                                fullWidth
                                value={editData.personaDescription}
                                multiline
                                rows={4}
                              />
                            )}
                          />
                          {errors.Persona_description && (
                            <span style={{ color: "red", display: "flex" }}>
                              *Description is required
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <FormControl fullWidth variant="outlined">
                            <Controller
                              name="Application_status"
                              rules={{ required: true }}
                              control={control}
                              defaultValue={1}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Age">
                                  <MenuItem value={1}>Pending</MenuItem>
                                </Select>
                              )}
                            />
                            {errors.Application_status && (
                              <span style={{ color: "red", display: "flex" }}>
                                *Application_status is required
                              </span>
                            )}
                          </FormControl>
                        </Grid>
                        <div style={{ display: "flex" }}>
                          <CardActions>
                            <Button
                              style={{ width: "98px" }}
                              variant="contained"
                              color="primary"
                              type="button"
                              onClick={handleShow}
                              className={classes.button}>
                              Add Role
                            </Button>
                          </CardActions>

                          <CardActions>
                            <Button
                              variant="contained"
                              color="primary"
                              type="button"
                              onClick={handleShow}
                              className={classes.button}>
                              Edit
                            </Button>
                          </CardActions>

                          <CardActions>
                            <Button
                              variant="contained"
                              color="primary"
                              type="reset"
                              onClick={() => setCollections([])}
                              className={classes.button}>
                              Delete
                            </Button>
                          </CardActions>
                        </div>
                      </Grid>
                    </CardContent>
                    <div style={{ display: "flex", float: "right" }}>
                      <CardActions>
                        <Button
                          variant="contained"
                          color="primary"
                          type="Submit"
                          className={classes.button}>
                          Submit
                        </Button>
                      </CardActions>
                      <CardActions>
                        <Button
                          variant="contained"
                          color="primary"
                          type="reset"
                          onClick={() => history.push("/PersonaManagement")}
                          className={classes.button}>
                          Cancel
                        </Button>
                      </CardActions>
                    </div>
                  </form>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <Personamodal
        addToCollections={addToCollections}
        show={show}
        handleClose={handleClose}
      />
    </>
  );
};

export default CreatePersona;
