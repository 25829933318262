// ===============================Table=================================

import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Background from "../assets/pcore_image.png";
import "./PersonaManagement.css";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Popper from "@material-ui/core/Popper";
import Usertable from "../components/CreateUserList";
import Createpersonamodel from "./Createpersonamodel";
import { Dropdown } from "primereact/dropdown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { string } from "yup/lib/locale";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const styles = (theme) => ({
  popper: {
    width: "fit-content",
  },
});

export default function Personamanagement() {
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;
  const Url = process.env.BASE_URL;

  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState();

  const [contData, setContData] = useState([]);

  const [PersonaNames, setPersonaNames] = useState(null);
  const [PersonaData, setPersonaData] = useState([]);
  const [domainValues, setDomainValues] = useState();
  const [values, setValues] = useState();
  const [condition1, setcondition1] = useState(false);
  const [condition2, setcondition2] = useState(false);
  const [propValue, setPropValue] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [createModalShow, setCreateModalShow] = useState(false);
  const [dataStatus, setDataStatus] = useState(null);
  const [collections, setCollections] = useState([]);

  const addToCollections = (data) => {
    setCollections((prev) => [...prev, data]);
  };

  const history = useHistory();

  const personaGroupData = async () => {
    setLoading(true)
    await axios
      .get(Url + "get-persona-domains", {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        var obj = {};
        var array = [];

        data.data.personaGroups.map((item, i) => {
          obj = { label: item.personaName, id: item.personaId };
          array.push(obj);
        });
        const domain = data.data.domains;

        const OthersSplice = domain.splice(domain.indexOf("Others"), 1);
        const sortDom = [...domain.sort(), OthersSplice.toString()];
        setDomains(sortDom);
        setPersonaData(data.data.personaGroups);
        setLoading(false)
      })
      .catch((err) => console.log(err));
  };

  function appFilter(str) {
    setContData([]);
    setDataStatus("Select Persona");
    setcondition2(false);
    const personaFilter = PersonaData.filter((ele) => {
      return ele.domains.some((subEle) => {
        return subEle == str;
      });
    });
    personaFilter.sort((a, b) => {
      if (a.personaName.toLowerCase() < b.personaName.toLowerCase()) {
        return -1;
      } else if (a.personaName.toLowerCase() > b.personaName.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    setPersonaNames(personaFilter);
  }

  const personaGroupDataUpdate = async () => {
    await axios
      .get(Url + "get-persona-domains", {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        var obj1 = {};
        var array1 = [];

        data.data.personaGroups.map((item, i) => {
          obj1 = { label: item.personaName, id: item.personaId };
          array1.push(obj1);
        });
        setPersonaData(data.data.personaGroups);
      })
      .catch((err) => console.log(err));
    };
    useEffect(()=>{

      personaOnchange(values)
    },[PersonaData])
  useEffect(() => {
    personaGroupData();
  }, []);

  var desc;
  var Roledata = [];
  var SubmitData;
  var Approver;

  const personaOnchange = (id) => {
    PersonaData &&
      PersonaData.map((data) => {
        if (data.personaId == id && data.personaDescription !== "") {
          setContData([data]);
          setDataStatus();
        }
      });
  };

  const PopperMy = function (props) {
    return <Popper {...props} style={styles.popper} placement="bottom-start" />;
  };

  const concat = (x, y) => x.concat(y);
  const flatMap = (f, xs) => xs.map(f).reduce(concat, []);
  let groups = flatMap(
    (c) => c.groups.map((s) => ({ mainPersona: c, groupsValue: s })),
    contData && contData
  );

  useEffect(() => {
    if (values) {
      condition2 ? setcondition1(false) : setcondition1(true);
    }
  }, [values]);

  const design = {
    border: "solid",
    borderColor: "#ffff",
    borderWidth: "3px",
  };
  const colors = {
    color: "black",
    fontSize: "larger",
    fontWeight: "700",
  };
  const color = {
    verticalAlign: "baseline",
  };
  const ListofUser = () => {
    setcondition1(false);
    setcondition2(true);
  };
  const handledefinition = () => {
    setcondition2(false);
    setcondition1(true);
  };

  const handleClose = (str) => {
    setModalShow(false);
    setCreateModalShow(false);
    if (str === "postButtonClicked") {
      personaGroupDataUpdate();
    }
  };

  const editApprover = (ele) => {
    setModalShow(true);
  };

  const createApprover = (e) => {
    setCreateModalShow(true);
  };

  function firstPersonaInArray(str) {
    return (
      groups.filter((s) => s.personaName === str.personaName).indexOf(str) === 0
    );
  }

  function numberOfPersona(str) {
    return groups.filter((s) => s.personaName === str.personaName).length;
  }

  function firstGroupInArray(str) {
    return (
      groups
        .filter((s) => s.serviceRoleName === str.serviceRoleName)
        .indexOf(str) === 0
    );
  }

  function numberOfGroups(str) {
    return groups.filter((s) => s.serviceName === str.serviceName).length;
  }

  
  return (
    <div className="personaManagement">
      <div className="grid">
        <div className="Image">
          <img style={{ width: "100%" }} src={Background} alt="BG" />
        </div>
        <div className="FormBox" style={{ width: "100%" }}>
          <form className="Formout">
            <Box
              className="FormInn"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "30ch" },
                display: "flex",
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <div className="TextField">Business Unit</div>
                <div className="primereactDropdown">
                  <Dropdown
                    value={domainValues}
                    filter={true}
                    onChange={(e) => {
                      setValues(null);
                      setDomainValues(e.target.value);
                      appFilter(e.target.value);
                    }}
                    options={domains.map((opt) => ({ label: opt, value: opt }))}
                    dropdownIcon={<ArrowDropDownIcon fontSize="medium" />}
                    className="w-full md:w-14rem"
                  />
                </div>
              </div>
              <div>
                <div className="TextField">Persona</div>
                <div className="primereactDropdown">
                  <Dropdown
                    value={values}
                    filter={true}
                    scrollHeight={250}
                    onChange={(e) => {
                      setValues(e.target.value);
                      personaOnchange(e.target.value);
                    }}
                    options={PersonaNames}
                    optionLabel="personaName"
                    optionValue="personaId"
                    dropdownIcon={<ArrowDropDownIcon fontSize="medium" />}
                    className="w-full"
                  />
                </div>
              </div>
              <div>
                <Button
                  variant="contained"
                  sx={{ marginTop: "30px" }}
                  onClick={handledefinition}
                  type="button"
                >
                  Definition
                </Button>
                <br /> <br />
              </div>
              <div>
                <Button
                  variant="contained"
                  sx={{ marginTop: "30px" }}
                  onClick={ListofUser}
                  type="button"
                >
                  List Of Users
                </Button>
                <br /> <br />
              </div>
              <div>
                <Button
                  variant="contained"
                  sx={{ marginTop: "30px" }}
                  onClick={createApprover}
                  type="button"
                >
                  Create New
                </Button>
                <br /> <br />
              </div>
              <div>
                <br /> <br />
              </div>
              {}
            </Box>
          </form>
        </div>
        {condition1 && (
          <div className="tabelParent">
            <TableContainer component={Paper} style={design}>
              <Table sx={{ minWidth: "100%" }} aria-label="customized table">
                <TableHead colSpan={5}>
                  <TableRow>
                    <StyledTableCell style={colors}>
                      Application
                    </StyledTableCell>
                    <StyledTableCell style={colors}>
                      Application Role
                    </StyledTableCell>
                    <StyledTableCell style={colors}>
                      Application role Description
                    </StyledTableCell>
                    <StyledTableCell style={colors}>Approvers</StyledTableCell>
                    <StyledTableCell style={colors}>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>

                {groups && groups.length > 0 ? (
                  <TableBody>
                    {groups &&
                      groups.map((str, index) => (
                        <StyledTableRow key={index++}>
                          {firstGroupInArray(str) && (
                            <StyledTableCell
                              component="th"
                              scope="row"
                              rowSpan={numberOfGroups(str)}
                            >
                              {" "}
                              {str.groupsValue.serviceName}
                            </StyledTableCell>
                          )}
                          {firstGroupInArray(str) && (
                            <StyledTableCell
                              component="th"
                              scope="row"
                              rowSpan={numberOfGroups(str)}
                            >
                              {" "}
                              {str.groupsValue.oktaGroupName}
                            </StyledTableCell>
                          )}
                          {firstGroupInArray(str) && (
                            <StyledTableCell
                              component="th"
                              scope="row"
                              rowSpan={numberOfGroups(str)}
                            >
                              {" "}
                              {str.groupsValue.serviceRoleDescription}
                            </StyledTableCell>
                          )}

                          {firstPersonaInArray(str) && (
                            <StyledTableCell style={color}>
                              {str.mainPersona.approvers &&
                                str.mainPersona.approvers.map((data, index) => (
                                  <div key={index}> {data.approverName},</div>
                                ))}
                            </StyledTableCell>
                          )}
                          {firstPersonaInArray(str) && (
                            <StyledTableCell style={color}>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  setPropValue(str.mainPersona);
                                  editApprover(values);
                                }}
                                type="button"
                              >
                                Edit
                              </Button>
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell
                        className="novalue"
                        style={{
                          color: "rgb(225, 138, 24)",
                          fontSize: "large",
                          fontWeight: "700",
                          textAlign: "start",
                        }}
                        align="right"
                      >
                        {dataStatus || "No Data Found"}
                      </StyledTableCell>

                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        )}
        {condition2 && (
          <div style={{ width: "100%" }}>
            <Usertable props={values} />
          </div>
        )}
        {modalShow && (
          <Createpersonamodel
            addToCollections={addToCollections}
            show={modalShow}
            handleClose={handleClose}
            propsRole={propValue || groups[0].mainPersona}
            title="Edit Approvers"
          />
        )}
        {createModalShow && (
          <Createpersonamodel
            addToCollections={addToCollections}
            show={createModalShow}
            handleClose={handleClose}
            propsRole={null}
            title="Create Approvers"
          />
        )}
      </div>
    </div>
  );
}
