import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import _ from "lodash";
import axios from "axios";
import "./landingpage.css";
import Background from "../assets/pcore_image.png";
import { useOktaAuth } from "@okta/okta-react";
import MUIDataTable from "mui-datatables";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  centeredHeader: {
    "& span": {
      display: "flex",

      justifyContent: "center",

      alignItems: "center",

      fontSize: "15px",

      fontWeight: "600",
    },
  },

  centeredrow: {
    "& span": {
      display: "flex",

      justifyContent: "center",

      alignItems: "center",

      fontSize: "15px",

      fontWeight: "400",
    },
  },
}));

//const DataCl = testdata.data_classification;
function Identifers(test) {
  var inditm = "";
  {
    test.map((itm) => {
      if (inditm == "") {
        inditm = itm;
      } else {
        inditm = inditm + ", " + itm;
      }
    });
  }
  return inditm;
}
const ExpandableRowTable = (props) => {
  //const data = DgPage('get-pii-summary-table');
  const Url = process.env.BASE_URL;
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;
  const params = new URLSearchParams([["email", Email]]);
  const { oktaAuth } = useOktaAuth();
  const [DataCl, setDataCl] = useState([]);
  const classes = useStyles();
  const [tables, setTables] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filtered, setfiltered] = useState([]);
  const [identifiers, setIdentifiers] = useState([]);
  const [db, setDB] = useState([]);
  const [schema, setSchema] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowColumnData, setRowColumnData] = useState(null);
  const [sampleData, setSampleData] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [rowExpanded, setRowExpaded] = useState([]);
  const [columnDataModal, setColumnDataModal] = useState(false);
  // const [handleSelectBool, setHandleSelectBool] = useState(true)
  // const [filterInitialized,setFilterInitialized]=useState(false)
  const [filter, setFilter] = useState({
    source: "",
    identifiers: "",
    table: "",
    schema: "",
    database: "",
  });
  var Email = oktaAuth.authStateManager._authState.idToken.claims.email;
  // const toastId=useRef(null)
  useEffect(() => {
    let dgPageToastId = toast.loading(
      "Please wait, Fetching data from server..."
    );

    axios
      .get(Url + `get-pii-data`, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        toast.dismiss(dgPageToastId);
        toast.success("Data loaded successfully", {
          autoClose: 750,
          // id:dgPageToastId
        });
        // toast.update(dgPageToastId, { render: "Data loaded successfully", type: "success",autoClose:750, isLoading: false });

        const data1 =
          data.data["data_classification"] !== null
            ? data.data["data_classification"]
            : "Currently No Identifers.";
        setDataCl(data1);
        getValues(data1);
        const roleData1 = [];
        {
          data1 &&
            data1.map((data) => {
              roleData1.push([
                data.database_name,
                data.schema_name,
                data.table_name,
                "snowflake",
                Identifers(data.identifiers),
                data.pii_count,
                data.subfields,
              ]);
            });
        }
        console.log(roleData1, "oooooo", data1);
        setIsLoading(false);
        setTableData(roleData1);
        setfiltered(roleData1);
      })
      .catch((err) => {
        toast.dismiss(dgPageToastId);
        toast.error("Data loading has failed.", {
          autoClose: 2000,
        });
        // toast.update(dgPageToastId, { render: "Data loading failed.", type: "error",autoClose:4000, isLoading: false });
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const dataIndex = {
    source: 3,
    identifiers: 4,
    database: 0,
    schema: 1,
    table: 2,
  };

  const getValues = (data1) => {
    let val = ["object_name"],
      temp = [{ label: "All", value: "select" }],
      dbName = [],
      temp2 = [],
      schema = [];
    _.map(data1, (i) => {
      let obj = {},
        obj2 = {};
      obj.label = i.table_name;
      obj.value = i.table_name;

      temp.push(obj);
    });
    _.map(data1, (i, index) => {
      _.map(i.identifiers, (j) => {
        if (!_.includes(temp, j)) {
          temp2.push(j);
        }
      });
      if (!_.includes(temp, i.database_name)) {
        dbName.push(i.database_name);
      }
      schema.push(i.schema_name);
    });
    setTables(temp);
    setDB(selectVal(_.uniq(dbName)));
    setSchema(selectVal(_.uniq(schema)));
    setIdentifiers(selectVal(_.uniq(temp2)));
  };

  const selectVal = (data, val) => {
    let temp = [{ label: "All", value: "select" }];
    _.map(data, (i) => {
      let obj = {};
      obj.label = val == undefined ? i : i.val;
      obj.value = val == undefined ? i : i.val;

      temp.push(obj);
    });
    return temp;
  };

  const handleSelect = (e, type, temp) => {
    //
    console.log(e, "value", tableData, e.length, type, temp);
    let data = tableData,
      str,
      arr = [],
      temp_arr = [],
      filterInitialized = false,
      handleSelectBool = true,
      check = [];
    console.log(filter, "filters");
    let fil = filter;
    fil[temp] = e.length > 0 ? e : "";

    console.log(arr.length, "line194");
    Object.keys(fil).forEach((key, index) => {
      fil[key] != "" ? check.push(fil[key]) : "";
    });
    setFilter(fil);

    Object.keys(fil).forEach((key, obj_index) => {
      console.log("fill", fil, check.length);
      console.log(key, fil[key], "key", dataIndex[key]);
      let filArray = fil[key],
        rowIndex = dataIndex[key];
      console.log(temp_arr, "line212");
      if (filArray.value != "select" && check.length > 0) {
        // if (filArray.value != "select" && filArray.length > 0) {
        if (filArray != "") {
          console.log("worked", filArray, rowIndex);
          if (rowIndex == 4) {
            console.log(typeof arr, "type");
            console.log(arr.length, "arrrayyy length", typeof arr);
            const mapFilterInitialized = filterInitialized;
            _.map(filArray, (item, index) => {
              const filteredData = _.filter(
                mapFilterInitialized ? temp_arr : data,
                (j) => j[rowIndex].includes(item.value)
              );
              if (index > 0) {
                // console.log('first', arr.length ? arr : data)
                // const filteredData = _.filter(arr.length?arr:data, (j) => j[4].includes(item.value));
                // if (handleSelectBool){
                //   arr=[...filteredData]
                //   setHandleSelectBool(false)
                // }else{

                arr = Array.from(
                  new Set([...arr, ...filteredData].map(JSON.stringify))
                );

                // }
                // console.log(filteredData, 'filterdata iden worked', arr)
              } else {
                console.log("first else", arr.length ? arr : data);
                // console.log(filteredData,'else filterdata iden worked')
                // const filteredData=_.filter(arr.length?arr:data, (j) => _.includes(j[4], item.value))
                // const filteredData = _.filter(data, (j) => _.includes(j[4], item.value))
                arr = [...filteredData];
                if (handleSelectBool) {
                  handleSelectBool = false;
                }
                console.log(arr, "arrrayyyiden");
              }
              filterInitialized = true;
            });
            temp_arr = [...arr];
            // setfiltered(arr[0]);
            // setfiltered(arr);
          } else if (rowIndex == 0) {
            // console.log(arr, "arrrayyy");
            const mapFilterInitialized = filterInitialized;
            _.map(filArray, (item, index) => {
              console.log(temp_arr, "line269", index);
              // if (index > 0) {
              const filteredData = _.filter(
                mapFilterInitialized ? temp_arr : data,
                (j) => j[rowIndex].includes(item.value)
              );

              if (index > 0) {
                // _.filter(data, (j) => {
                //   // _.includes(j[4], item.value) ? arr[0].push(j) : "";
                //   j[4].includes( item.value) ? arr[0].push(j) : "";
                // });
                // arr[0].push(...filteredData)
                // if (handleSelectBool){
                //   arr=[...filteredData]
                //   setHandleSelectBool(false)
                // }else{
                arr = Array.from(
                  new Set([...arr, ...filteredData].map(JSON.stringify))
                );

                // }
                console.log(
                  filteredData.length,
                  "filterdata database worked",
                  arr
                );
              } else {
                //   // console.log(filteredData,'else filterdata iden worked')
                // const filteredData = _.filter(arr.length?arr:data, (j) => j[0].includes(item.value));
                //  arr.push(...filteredData);
                arr = [...filteredData];
                if (handleSelectBool) {
                  handleSelectBool = false;
                }

                //    console.log(arr, "arrrayyyiden");
              }
              filterInitialized = true;
            });

            temp_arr = [...arr];
            // setfiltered(arr[0]);
            // setfiltered(arr);
          } else if (rowIndex == 1) {
            const mapFilterInitialized = filterInitialized;
            _.map(filArray, (item, index) => {
              // if (index > 0) {
              // _.map(arr.length?arr:data, (j) => {
              //   j[rowIndex] == item.value ? arr.push(j) : "";
              // });

              // const filteredData = _.filter(filterInitialized ? temp_arr : data, (j) => _.includes(j[rowIndex], item.value))
              const filteredData = _.filter(
                mapFilterInitialized ? temp_arr : data,
                (j) => j[rowIndex] == item.value
              );

              if (index > 0) {
                arr = Array.from(
                  new Set([...arr, ...filteredData].map(JSON.stringify))
                );
              } else {
                arr = [...filteredData];
                if (handleSelectBool) {
                  handleSelectBool = false;
                }
                //  else {
                //   // arr.push(...filteredData)
                //   arr = [...filteredData]

                // }
              }
              filterInitialized = true;
            });
            temp_arr = [...arr];
          } else {
            const mapFilterInitialized = filterInitialized;
            _.map(filArray, (item, index) => {
              const filteredData = _.filter(
                mapFilterInitialized ? temp_arr : data,
                (j) => j[rowIndex] == item.value
              );
              if (index > 0) {
                arr = Array.from(
                  new Set([...arr, ...filteredData].map(JSON.stringify))
                );
              } else {
                arr = [...filteredData];
                if (handleSelectBool) {
                  handleSelectBool = false;
                }
              }
              filterInitialized = true;
            });
            temp_arr = [...arr];
          }
          // setFilterInitialized(true)
          console.log(arr, "arrray");

          // setfiltered(arr);
          console.log(filtered, "filtereeeee");
        }
      } else {
      }
    });

    //setfiltered(arr.length ? arr : data);
    setfiltered(filterInitialized ? arr : data);
    // setHandleSelectBool(true)
    console.log(filtered.length, "filtered.length", filtered);
  };

  const handleViewColumnData = () => {
    // debugger
    let toastId = toast.loading("Please wait, Fetching data...");
    console.log(rowColumnData, 382);
    const url = new URL(Url + `get-dpds-sample-columnar-info`);
    url.searchParams.set("database-name", rowColumnData[0]);
    url.searchParams.append("schema-name", rowColumnData[1]);
    url.searchParams.append("table-name", rowColumnData[2]);
    // url.searchParams.append("column-name", rowColumnData[6]);
    url.searchParams.append(
      "column-name",
      rowColumnData[6].map((item) => item["Column Name"]).join(",")
    );

    axios
      .get(url, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        setColumnDataModal(true);
        // debugger
        toast.dismiss(toastId);
        toast.success("Data loaded successfully", { autoClose: 750 });
        // toast.update(toastId, { render: "Data loaded successfully", type: "success",autoClose:500, isLoading: false });
        setSampleData(data.data);
        // const data1 =
        //   data.data["data_classification"] !== null
        //     ? data.data["data_classification"]
        //     : "Currently No Identifers.";
        // setDataCl(data1);
        // getValues(data1);
        // const roleData1 = [];
        // {
        //   data1 &&
        //     data1.map((data) => {
        //       roleData1.push([
        //         data.database_name,
        //         data.schema_name,
        //         data.table_name,
        //         "snowflake",
        //         Identifers(data.identifiers),
        //         data.pii_count,
        //         data.subfields,
        //       ]);
        //     });
        // }
        console.log(data.data, "oooooo");
        // setIsLoading(false)
        // setTableData(roleData1);
        // setfiltered(roleData1);
      })
      .catch((err) => {
        // debugger
        toast.dismiss(toastId);
        toast.error("Data loading failed.", { autoClose: 1500 });
        // toast.update(toastId, { render: "Data loading failed.", type: "error",autoClose:4000, isLoading: false });
        // setIsLoading(false)
        console.log(err);
      });
  };

  const columns = [
    {
      name: "Database Name",
      width: 300,
      filter: false,
    },
    {
      name: "Schema Name",
      width: 300,
      filter: false,
    },
    {
      name: "Table Name",
      width: 300,
      filter: false,
    },
    {
      name: "Data Source",
      width: 300,
      filter: false,
    },
    {
      name: "Identifiers",
      width: 300,
    },
    {
      name: "PII Column Count",
      width: 300,
    },
  ];

  const options = {
    selectableRowsHideCheckboxes: true,
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList, "onfilterchange");
    },
    setRowProps: () => ({ className: classes.centeredrow }),
    selectableRows: "single",
    filterType: "multiselect",
    responsive: "scrollMaxHeight",
    rowsPerPage: 30,
    expandableRows: true,
    rowsExpanded: rowExpanded,
    download: false,
    // filter: false,
    print: false,
    search: false,
    viewColumns: false,
    rowsPerPageOptions: [30, 60, 100],
    textLabels: {
      body: {
        noMatch: isLoading ? "Loading the Data....." : "No data found",
      },
    },
    onTableChange: (action, tableState) => {
      console.log(action, "tablestate");
      switch (action) {
        case "rowExpansionChange":
          console.log(action, "tablestate11");
          // console.dir(tableState,'TabexpandRow');
          var rowsExpanded = tableState.expandedRows.data.map(
            (item) => item.index
          );

          if (rowsExpanded.length > 1) {
            // limiting would go here
            rowsExpanded = rowsExpanded.slice(-1);
          }
          console.log(rowsExpanded, "tablestate");
          // console.dir(rowsExpanded,'tablestate');

          setRowExpaded(rowsExpanded);

          break;
      }
    },
    renderExpandableRow: (rowData, rowMeta) => {
      console.log("rowData", rowData, "rowMeta", rowMeta, filtered);

      const { expanded } = rowMeta;

      setRowColumnData(filtered[rowMeta["dataIndex"]]);

      console.log(filtered[rowMeta["dataIndex"]][6], "expandedIndex");
      return (
        <React.Fragment>
          <tr>
            <td colSpan={7}>
              <TableContainer component={Paper}>
                <Table style={{ width: "100%" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <h6 className="secondaryTable">Column Name</h6>
                      </TableCell>
                      <TableCell align="center">
                        <h6 className="secondaryTable">Privacy Category</h6>
                      </TableCell>
                      <TableCell align="center">
                        <h6 className="secondaryTable">Semantic Category</h6>
                      </TableCell>
                      <TableCell align="center">
                        <h6 className="secondaryTable">Tag</h6>
                      </TableCell>
                      <TableCell align="center">
                        <h6 className="secondaryTable">Probability</h6>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filtered[rowMeta["dataIndex"]][6].map((row) => (
                      <TableRow key={row["Column Name"]}>
                        <TableCell align="center" component="th" scope="row">
                          {row["Column Name"]}
                        </TableCell>
                        <TableCell align="center">
                          {row["Privacy Category"]}
                        </TableCell>
                        <TableCell align="center">
                          {row["Semantic Category"]}
                        </TableCell>
                        <TableCell align="center">{row.T}</TableCell>
                        <TableCell align="center">{row.Probability}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    // onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) =>{
    //     console.log(curExpanded, 'allExpanded',allExpanded, 'rowsExpanded',rowsExpanded,'new')
    //     debugger
    //     if (curExpanded[0].dataIndex==selectedRowIndex){
    //       console.log('from if')
    //       setRowColumnData(null)
    //     setSelectedRowIndex(null)}else{
    //       console.log('from else')
    //       setSelectedRowIndex(curExpanded[0].dataIndex)

    //     }

    //   },

    page: 0,
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  return (
    <div className="Outertext">
      <div>
        <img src={Background} alt="BG" />
      </div>
      <br></br>
      {console.log(tableData, "tableData", tables)}
      <div className="cf-mt FormBox">
        <Form className="d-flex flex-wrap flex-sm-row hoverBorder project-form org">
          <Form.Group className="form-input filter-table">
            <label className="form-label form-label">Data Source</label>
            <Select
              styles={{
                control: () => ({
                  height: 33,
                  display: "flex",
                  borderRadius: "4px",
                  fontSize: 13,
                  border: "1px solid lightgray",
                  background: "white",
                }),
              }}
              className="dropdownSizeSummary"
              defaultValue={{ label: "snowflake", value: "snowflake" }}
              isMulti={true}
              onChange={(e) => handleSelect(e, 2, "source")}
              options={[{ label: "snowflake", value: "snowflake" }]}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </Form.Group>
          <Form.Group className="form-input filter-table-db">
            <label className="form-label form-label">Database Name</label>
            <Select
              styles={{
                control: () => ({
                  height: 33,
                  display: "flex",
                  borderRadius: "4px",
                  fontSize: 13,
                  border: "1px solid lightgray",
                  background: "white",
                }),
              }}
              className="dropdownSizeSummary outlined-select-currency"
              // defaultValue={}
              isMulti={true}
              onChange={(e) => handleSelect(e, 0, "database")}
              options={db}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </Form.Group>
          <Form.Group className="form-input filter-table">
            <label className="form-label">Schema Name</label>
            <Select
              styles={{
                control: () => ({
                  height: 33,
                  display: "flex",
                  borderRadius: "4px",
                  fontSize: 13,
                  border: "1px solid lightgray",
                  background: "white",
                }),
              }}
              className="dropdownSizeSummary outlined-select-currency"
              // defaultValue={}
              isMulti={true}
              onChange={(e) => handleSelect(e, 1, "schema")}
              options={schema}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </Form.Group>
          <Form.Group className="form-input filter-table">
            <label className="form-label form-label">Identifiers</label>
            <Select
              styles={{
                control: () => ({
                  height: 33,
                  display: "flex",
                  borderRadius: "4px",
                  fontSize: 13,
                  border: "1px solid lightgray",
                  background: "white",
                }),
              }}
              className="dropdownSizeSummary"
              // defaultValue={}
              isMulti={true}
              onChange={(e) => handleSelect(e, "iden", "identifiers")}
              options={identifiers}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </Form.Group>
          <Form.Group className="form-input filter-table-name">
            <label className="form-label form-label">Table Name</label>
            <Select
              styles={{
                control: () => ({
                  height: 33,
                  display: "flex",
                  borderRadius: "4px",
                  fontSize: 13,
                  border: "1px solid lightgray",
                  background: "white",
                }),
              }}
              className="dropdownSizeSummary outlined-select-currency"
              // defaultValue={}
              isMulti={true}
              onChange={(e) => handleSelect(e, 2, "table")}
              options={tables}
              // closeOnSelect={(e) => console.log(e, "close", )}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </Form.Group>
        </Form>
      </div>
      <div>
        {/* {console.log(filtered, 'returndata')} */}
        <div
          style={{
            width: "100%",
            justifyContent: "flex-end",
            display: "flex",
            marginTop: ".5rem",
            marginBottom: ".5rem",
          }}
        >
          {console.log(rowColumnData, "rowreturn")}
          <Button
            disabled={!rowExpanded.length}
            style={{ textTransform: "none" }}
            variant="contained"
            onClick={(e) => {
              handleViewColumnData();
            }}
          >
            View Data
          </Button>
        </div>
        <MUIDataTable columns={columns} data={filtered} options={options} />
        {columnDataModal && (
          <Dialog
            open={columnDataModal}
            onClose={() => setColumnDataModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
            fullWidth
          >
            <DialogTitle
              style={{
                color: "black",
                fontSize: "16px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p style={{ fontWeight: "bold" }}>Sample Column Data</p>{" "}
              <Button onClick={() => setColumnDataModal(false)}>
                <CloseIcon />
              </Button>
            </DialogTitle>
            <DialogContent>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: "75%" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                  
                      {sampleData &&
                        sampleData?.column_names.map((col, index) => (
                          <TableCell
                            style={{
                              minWidth: "50px",
                              maxWidth: "175px",
                              padding: "5px",
                              color: "rgb(25, 118, 210)",
                            }}
                            align="center"
                          >
                            <h6 style={{ fontWeight: 600, fontSize: "10px" }}>
                              {col.toLowerCase().replace("_", " ")}
                            </h6>
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sampleData?.sample_data.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {row.map((ele) => (
                          <TableCell
                            align="center"
                            scope="row"
                            style={{ fontSize: "9px", padding: "0px 5px" }}
                          >
                            {typeof ele === "string"
                              ? truncateText(ele, 50)
                              : ele}
                           
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <DialogContentText id="alert-dialog-description">
                  - Click "Continue" to fill in required details.
                  <br />- Click "Reset" to clear the "AND/OR" selection.
                </DialogContentText> */}
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={() => showConfirm("reset")}>Reset</Button>
                <Button onClick={() => showConfirm("continue")} autoFocus>
                  Continue
                </Button> */}
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  );
};
export default ExpandableRowTable;