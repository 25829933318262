import * as React from "react";
import { useHistory } from "react-router-dom";
import { FormControl, Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import Background from "../assets/pcore_image.png";
import "./SubmissionPage.css";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Popper from "@material-ui/core/Popper";
import { Dropdown } from "primereact/dropdown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    "& .MuiInputBase-root": {
      width: "200px", // set the desired width
    },
    "& .MuiFormControl-root": {
      margin: "auto",
    },
    "& .MuiTextField-root": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
  },
}));

const styles = (theme) => ({
  popper: {
    width: "fit-content",
  },
});

function SelectTextFields() {
  const current_date = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  );
  const [open, setOpen] = React.useState(false);
  const { oktaAuth } = useOktaAuth();
  const [values, setValues] = React.useState();
  const [domainValues, setDomainValues] = React.useState();
  const [date, setDate] = React.useState(current_date);
  const [domains, setDomains] = useState([]);
  const [PersonaData, setPersonaData] = useState([]);
  const [PersonaNames, setPersonaNames] = useState([]);
  const [contData, setContData] = useState(null);
  const [auto, setAuto] = useState(true);
  const autocompleteRef = useRef(null);

  const Url = process.env.BASE_URL;

  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;

  const history = useHistory();
  var Email = oktaAuth.authStateManager._authState.idToken.claims.email;
  var UserId = oktaAuth.authStateManager._authState.idToken.claims.idp;

  const classes = useStyles();

  const personaOnchange = (id) => {
    let today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    let day = tomorrow.getDate();
    let month = tomorrow.getMonth() + 1; // Months are zero-based
    let year = tomorrow.getFullYear();
    let exprStr = `The Selected Persona will Expire mid-night UTC on ${day}/${month}/${year}`;
    console.log(exprStr, "ttt");

    PersonaData &&
      PersonaData.map((data) => {
        if (data.personaId == id && data.personaDescription !== "") {
          setContData(data);
          if (id == 11) {
            setDate(tomorrow);
            alert(exprStr);
          }
        }
      });
  };
  
  const PopperMy = function (props) {
    return <Popper {...props} style={styles.popper} placement="bottom-start" />;
  };
  useEffect(() => {
    setValues(null);
  }, [PersonaNames]);

  useEffect(() => {
    axios
      .get(Url + "get-persona-domains", {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        setPersonaData(data.data.personaGroups);
        const domain = data.data.domains;

        const OthersSplice = domain.splice(domain.indexOf("Others"), 1);
        const sortDom = [...domain.sort(), OthersSplice.toString()];
        setDomains(sortDom);
      })
      .catch((err) => console.log(err));
  }, []);

  function appFilter(str) {
    setContData(null);
    setAuto(false);

    const personaFilter = PersonaData.filter((ele) => {
      return ele.domains.some((subEle) => {
        return subEle == str;
      });
    });
    personaFilter.sort((a, b) => {
      if (a.personaName.toLowerCase() < b.personaName.toLowerCase()) {
        return -1;
      } else if (a.personaName.toLowerCase() > b.personaName.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    setPersonaNames(personaFilter);
  }

  function handleSubmit(e) {
    e.preventDefault();
    // setOpen(!open);
    const environment = process.env.ENVIRONMENT;

    let Submit = {
      action: "add",
      personaId: contData?.personaId,
      email: Email,
      requester_email: Email,
      status: "pending",
      expiration: date.toLocaleDateString("sv"),
      env: environment,
    };
    console.log(Submit, "axax");
    axios
      .post(Url + "submit-request", Submit, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        if (data.data.success == true) {
          alert("Your request has been successfully submitted.")
          window.location.href = "/";
        } else {
          alert(data.data.message);
          <form>
            <input
              type="button"
              value="Click Me"
              onclick={(window.location.href = "/")}
            />
          </form>;
        }
      })
      .catch((err) => {
        console.log(err, "Error on Submit");
        alert(err);
        <form>
          <input
            type="button"
            value="Click Me"
            onclick={(window.location.href = "/")}
          />
        </form>;
      });
  }

  return (
    <div className="Submissionpage">
      <div className="grid">
        <div className="Images">
          <img src={Background} className="Imagesrc" alt="BG" />
        </div>
        <div className="FormBoxs">
          <form className="Formouts">
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "30ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <div className="TextField">Business Unit</div>
                <Dropdown
                  style={{ height: 45, marginTop: 8 }}
                  value={domainValues}
                  filter={true}
                  onChange={(e) => {
                    setValues(null);
                    setDomainValues(e.target.value);
                    appFilter(e.target.value);
                  }}
                  options={domains.map((opt) => ({ label: opt, value: opt }))}
                  dropdownIcon={<ArrowDropDownIcon fontSize="medium" />}
                  className="w-full md:w-14rem"
                />
              </div>
              <div>
                <div className="TextField">Persona</div>

                <Dropdown
                  style={{ height: 45, marginTop: 8 }}
                  value={values}
                  filter={true}
                  onChange={(e) => {
                    setValues(e.target.value);
                    personaOnchange(e.target.value);
                  }}
                  options={PersonaNames}
                  optionLabel="personaName"
                  optionValue="personaId"
                  dropdownIcon={<ArrowDropDownIcon fontSize="medium" />}
                  className="w-full md:w-14rem"
                />
              </div>
              <div className="TextField">Description</div>
              <div>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={3}
                  defaultValue={contData?.personaDescription || ""}
                  style={{ height: "90px" }}
                />
              </div>
              <div className="TextField">Expiration Date</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <div className={classes.datePicker}>
                    <DatePicker
                      value={date}
                      minDate={new Date()}
                      disablePast
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </div>
                </LocalizationProvider>
              </div>
              <div>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!values}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
                <br /> <br />
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div>
            </Box>
          </form>
        </div>

        <div className="tabelParents">
          {contData && contData.groups.length > 0 ? (
            <div className="TableContent">
              <TableContainer component={Paper} style={{ display: "flex" }}>
                <TableContainer
                  sx={{ maxHeight: 350, minHeight: 352 }}
                  style={{
                    border: "solid",
                    borderColor: "darkgrey",
                    borderWidth: "5px",
                  }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            fontSize: "large",
                            fontWeight: "700",
                          }}
                        >
                          Application
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontSize: "large",
                            fontWeight: "700",
                          }}
                        >
                          Application Role
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontSize: "large",
                            fontWeight: "700",
                          }}
                        >
                          Application Role Description
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontSize: "large",
                            fontWeight: "700",
                          }}
                        >
                          Approvers
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contData.groups &&
                        contData.groups.map((loaded_data) => (
                          <TableRow
                            key={loaded_data.serviceRoleName}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              align="left"
                              style={{
                                fontSize: "larger",
                                fontWeight: "500",
                              }}
                            >
                              {loaded_data.serviceName}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontSize: "larger",
                                fontWeight: "500",
                              }}
                            >
                              {loaded_data.serviceRoleName}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontSize: "larger",
                                fontWeight: "500",
                              }}
                            >
                              {loaded_data.serviceRoleDescription}
                            </TableCell>
                            {contData.approvers &&
                              contData.approvers.map((data, index) => (
                                <TableCell
                                  key={index}
                                  align="left"
                                  style={{
                                    fontSize: "larger",
                                    fontWeight: "500",
                                  }}
                                >
                                  {data.approverName}
                                </TableCell>
                              ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableContainer>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default function BasicTable() {
  return <SelectTextFields />;
}