import { Checkbox, CircularProgress, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import "./landingpage.css";

import Link from "@mui/material/Link";
import moment from "moment";
import SearchBar from "material-ui-search-bar";

function RoleTableRow(props) {
  const { row, revokeProp } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);

  const handleRowClick = (ele) => {
    if (["approved", "pending"].some((item) => item === ele.status)) {
      setSelectedId(
        ele.personaId === selectedId?.personaId ? null : ele
      );
    } else {
      setSelectedId(null);
    }
  };
  revokeProp(selectedId);

  return (
    <React.Fragment>
      {row?.userId ? (
        <>
          {row?.personas?.map((ddo, index) => (
            <React.Fragment key={index}>
              <TableRow
                sx={{ "& > *": { borderBottom: "unset" } }}
                hover
                onClick={() => {
                  handleRowClick(ddo);
                }}
                selected={ddo.personaId === selectedId?.personaId}
              >
                <TableCell>
                  {["approved", "pending"].some((ele) => ele === ddo.status) ? (
                    <Checkbox
                      checked={ddo.personaId === selectedId?.personaId}
                    />
                  ) : (
                    <Checkbox disabled />
                  )}

                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      setOpen(open === index ? -1 : index);
                    }}
                  >
                    {open === index ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell
                  scope="row"
                  style={{
                    fontSize: "larger",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  <Link href={ddo.jiraUrl} target="_blank">
                    {ddo.rumReference}
                  </Link>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontSize: "larger",
                    fontWeight: "500",
                  }}
                >
                  {ddo.personaName}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontSize: "larger",
                    fontWeight: "500",
                  }}
                >
                  {ddo.status}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontSize: "larger",
                    fontWeight: "500",
                  }}
                >
                  {moment(ddo.expiration_ts).isValid()
                    ? moment(ddo.expiration_ts).format("YYYY-MMM-DD")
                    : null}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontSize: "larger",
                    fontWeight: "500",
                  }}
                >
                  {moment(ddo.creation_ts).format("YYYY-MMM-DD")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse in={open === index} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{
                                color: "#1976d2",
                                fontSize: "medium",
                                fontWeight: "500",
                              }}
                            >
                              Application Name
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: "#1976d2",
                                fontSize: "medium",
                                fontWeight: "500",
                              }}
                            >
                              Application Role Name
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ddo &&
                            ddo.groups.map((dd) => (
                              <TableRow key={ddo.personaId}>
                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{
                                    fontSize: "medium",
                                    fontWeight: "500",
                                  }}
                                >
                                  {dd.serviceName}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{
                                    fontSize: "medium",
                                    fontWeight: "500",
                                  }}
                                >
                                  {dd.serviceRoleName}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </>
      ) : (
        <TableRow>
          <TableCell
            align="right"
            style={{
              color: "rgb(225, 138, 24)",
              fontSize: "large",
              fontWeight: "700",
            }}
          >
            No Roles Assigned Currently.
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default function RumadminRoleTable({ role, setRole, revokeProp, spinner }) {
  const roleStatusArr = [
    { key: "approved", value: "Approved", id: 2 },
    { key: "pending", value: "Pending", id: 3 },
    { key: "declined", value: "Declined", id: 4 },
    { key: "revoked", value: "Revoked", id: 5 },
    { key: "all", value: "All", id: 1 },
  ];

  const [activeButton, setActiveButton] = useState(roleStatusArr[0].key);
  const [searched, setSearched] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  if (role === "Currently No Roles Assigned.") {
    role = [];
  }

  const { oktaAuth } = useOktaAuth();
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;
  const userEmail = role[0]?.email;
  const Url = process.env.BASE_URL;

  useEffect(() => {
    if (userEmail) {
      getData();
    }
  }, [userEmail]);

  useEffect(() => {
    if (role[0]?.personas) {
      if (activeButton === "all") {
        return setFilteredData(role);
      }
      const newFilteredData = role[0].personas.filter(
        (item) => item?.status === activeButton
      );

      setFilteredData([{ ...role[0], personas: newFilteredData }]);
    }
  }, [activeButton, role[0]?.email]);

  const getData = () => {
    axios
      .get(`${Url}get-user-personas?email=${userEmail}`, {
        headers: { "rum-svc-acct": Svc, "rum-api-key": API_KEY },
      })
      .then((data) => {
        let data1 =
          data.data.userPersonas !== null
            ? data.data.userPersonas
            : "Currently No Roles Assigned.";
        if (data1 !== "Currently No Roles Assigned.") {
          setRole(data1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    if (
      !role ||
      role.length === 0 ||
      !role[0].personas ||
      role[0].personas.length === 0
    ) {
      // Return early if role or personas is undefined or empty
      return;
    }

    let filteredRows = role.map((item) => {
      return {
        ...item,
        personas: item.personas.filter((ddo) =>
          ddo.personaName.toLowerCase().includes(searchedVal.toLowerCase())
        ),
      };
    });

    setFilteredData(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };

  const appOnchange = (str) => {
    setActiveButton(str);
    // You can implement the logic to fetch data based on the selected app status here
  };

  return (
    <>
      <div className="FormBox" style={{ width: "100%", marginTop: "5px", marginBottom: "-5px", borderRadius: "5px", height: "70px" }}>
        <form className="Formout">
          <Box
            className="FormInn"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "30ch" },
              display: "flex",
              justifyContent: "space-between",
            }}
            noValidate
            autoComplete="off"
          >
            <div style={{ display: "flex", marginLeft: "40px" }}>
              {roleStatusArr.map((status) => (
                <button
                  key={status.id}
                  className={activeButton === status.key ? 'active status_button' : 'status_button'}
                  onClick={() => appOnchange(status.key)}
                  type="button"
                >
                  {status.value}
                </button>
              ))}
            </div>

            <div style={{ marginTop: "12px", marginRight: "20px" }}>
              <SearchBar
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
            </div>
          </Box>
        </form>
      </div>

      <div className="Outerbox">
        <TableContainer
          component={Paper}
          style={{ border: "solid", borderColor: "#ffff", borderWidth: "3px" }}
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>{""}</TableCell>

                <TableCell
                  align="center"
                  style={{
                    color: "black",
                    fontSize: "large",
                    fontWeight: "700",
                  }}
                >
                  RUM ID
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: "black",
                    fontSize: "large",
                    fontWeight: "700",
                  }}
                >
                  Persona Name
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: "black",
                    fontSize: "large",
                    fontWeight: "700",
                  }}
                >
                  STATUS
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: "black",
                    fontSize: "large",
                    fontWeight: "700",
                  }}
                >
                  Expiration Date
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: "black",
                    fontSize: "large",
                    fontWeight: "700",
                  }}
                >
                  Creation Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.length ? (
                filteredData.map((r, i) => (
                  <RoleTableRow key={i} row={r} revokeProp={revokeProp} />
                ))
              ) : (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "rgb(225, 138, 24)",
                      fontSize: "large",
                      fontWeight: "700",
                    }}
                  >
                    {(spinner && <CircularProgress color="inherit" />) ||
                      "No Roles Assigned Currently."}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
