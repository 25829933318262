import * as React from "react";
import Card from "@mui/material/Card";
import { Grid } from "@material-ui/core/";
import "./Front_Page.css";
import { useHistory } from "react-router-dom";

export default function NewUserNote() {
  const history = useHistory();
  return (
    <Card
      variant="outlined"
      sx={{
        padding: "2rem 1rem",
        textAlign: "center",
        background: "#f9b35de0",
      }}
    >
      <br />

      <Grid
        container
        spacing={5}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <h4 style={{ color: "black" }}>
          New to <b style={{ color: "black" }}>RUM?</b>
        </h4>
        <h5 style={{ width: "75%", color: "black" }}>
          {" "}
          Try the <b style={{ color: "black" }}>My Roles</b> button above to see
          all the data systems and roles you are assigned. Do you see one
          missing?<br/> Try requesting that role using the{" "}
          <b style={{ color: "black" }}>New Request button</b>.
        </h5>
        <h6 style={{ color: "black" }}>
          Do you have Questions? Contact:{" "}
          <b style={{ color: "black" }}>
            <a
              style={{ color: "blue" }}
              href="mailto:rumsupport@procore.com?body=Please describe your Questions"
            >
              rumsupport@procore.com
            </a>
          </b>
        </h6>
      </Grid>
    </Card>
  );
}
