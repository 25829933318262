import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import "./DeleteModal.css";
import Form from "react-bootstrap/Form";
import Select from "react-select";
export default function SelectBox(props) {
  // const { onClose, open, handleRevokeSubmit,actionType } = props;
  console.log(props, "props");
  const [identifiers, setIdentifiers] = useState([]);
  const [db, setDB] = useState([]);
  const [schema, setSchema] = useState([]);

  return (
    <Form className="d-flex flex-wrap flex-sm-row select-cover ">
      <Form.Group className={"form-input " + props.tableClass}>
        {props.label && (
          <label className="form-label form-label">Data Source</label>
        )}
        <Select
          styles={{
            control: () => ({
              height: 33,
              display: "flex",
              borderRadius: "4px",
              fontSize: 13,
              border: "1px solid lightgray",
              background: "white",
            }),
          }}
          className="dropdownSizeSummary"
          defaultValue={{ label: "snowflake", value: "snowflake" }}
          isMulti={true}
          // onChange={(e) => props.handleSelect(e, 2, "source")}
          options={[{ label: "snowflake", value: "snowflake" }]}
          components={{
            IndicatorSeparator: () => null,
          }}
          placeholder={props.placeholder[0]}
        />
      </Form.Group>
      <Form.Group className={"form-input " + props.tableClass}>
        {props.label && (
          <label className="form-label form-label">Database Name</label>
        )}
        <Select
          styles={{
            control: () => ({
              height: 33,
              display: "flex",
              borderRadius: "4px",
              fontSize: 13,
              border: "1px solid lightgray",
              background: "white",
            }),
          }}
          className="dropdownSizeSummary outlined-select-currency"
          // defaultValue={}
          isMulti={true}
          onChange={(e) => props.handleSelect(e, 0, "database_names")}
          options={props.db}
          value={props.databaseValue}
          components={{
            IndicatorSeparator: () => null,
          }}
          placeholder={props.placeholder[1]}
        />
      </Form.Group>
      <Form.Group className={"form-input " + props.tableClass}>
        {props.label && <label className="form-label">Schema Name</label>}
        <Select
          styles={{
            control: () => ({
              height: 33,
              display: "flex",
              borderRadius: "4px",
              fontSize: 13,
              border: "1px solid lightgray",
              background: "white"
            }),
          }}
          className="dropdownSizeSummary outlined-select-currency"
          // defaultValue={}
          // isDisabled={props.desc.database_names.length == 0}
          isMulti={true}
          onChange={(e) => props.handleSelect(e, 1, "schema_names")}
          options={props.schema}
          value={props.schemaValue}
          components={{
            IndicatorSeparator: () => null,
          }}
          placeholder={props.placeholder[2]}
        />
      </Form.Group>
      {/* <Form.Group className="form-input filter-table">
          <label className="form-label form-label">Identifiers</label>
          <Select
            styles={{
              control: () => ({
                height: 33,
                display: "flex",
                borderRadius: "4px",
                fontSize: 13,
                border: "1px solid lightgray",
                background: "#e9ecef",
              }),
            }}
            className="dropdownSizeSummary"
            // defaultValue={}
            isMulti={true}
            onChange={(e) => props.handleSelect(e, "iden", "identifiers")}
            options={identifiers}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </Form.Group> */}
      <Form.Group className={"form-input " + props.tableClass}>
        {props.label && (
          <label className="form-label form-label">Table Name</label>
        )}
        <Select
          styles={{
            control: () => ({
              height: 33,
              display: "flex",
              borderRadius: "4px",
              fontSize: 13,
              border: "1px solid lightgray",
              background: "white",
            }),
          }}
          className="dropdownSizeSummary outlined-select-currency"
          // defaultValue={}
          // isDisabled={props.desc.database_names.length == 0}
          isMulti={true}
          onChange={(e) => props.handleSelect(e, 2, "table_names")}
          options={props.tables}
          value={props.tableValue}
          placeholder={props.placeholder[3]}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </Form.Group>
    </Form>
  );
}