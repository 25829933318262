import { TableCell } from "@mui/material";
import React, { useState } from "react";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function TableTrim({ text = "", updatequery }) {
  const [showFullText, setShowText] = useState(false);
  const trimmedText =
    text.length > 30 ? text.substring(0, 30) + "........." : text;

  return (
    <>
      <StyledTableCell
        
        component="th"
        scope="row"
        style={{
          fontSize: "larger",
          fontWeight: "200",
          width: 70,
        }}>
        {}
        {trimmedText}
      </StyledTableCell>
    </>
  );
}
