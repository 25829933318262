import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useEffect, useState } from "react";
import Background from "../assets/pcore_image.png";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import Typography from "@mui/material/Typography";
import { useOktaAuth } from "@okta/okta-react";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./My_Queries.css";
import TableTrim from "./TableTrim";
import { Card } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function BasicTable() {
  const Url = process.env.BASE_URL;
  const { oktaAuth } = useOktaAuth();
  const [role, setRole] = useState([]);
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;
  var Email = oktaAuth.authStateManager._authState.idToken.claims.email;
  var roleData = [];
  const params = new URLSearchParams([["user", Email]]);

  const [query, setQuery] = useState(null);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const updatequery = value => {
    setQuery(value);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        Url + `get-snowflake-user-query-history?user=${Email}`,
        {
          headers: {
            "rum-svc-acct": Svc,
            "rum-api-key": API_KEY,
          },
        }
      )
      .then(data => {
        const data1 =
          data.data.userQueries !== null ? data.data.userQueries : [];

        setRole(data1);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  if (typeof role == "string") {
    roleData.push(role);
  } else {
    role &&
      role.map(data => {
        roleData.push(data);
      });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const design = {
    border: "solid",
    borderColor: "#ffff",
    borderWidth: "3px",
  };
  const color = {
    fontSize: "larger",
    fontWeight: "400",
    width: 70,
  };
  const colors = {
    color: "black",
    fontSize: "larger",
    fontWeight: "700",
    width: 70,
  };

  return (
    <>
      <div className="Outerbox">
        <div>
          <img style={{ width: "100%" }} src={Background} alt="BG" />
        </div>
        <div className="FormBox" style={{ width: "100%" }}>
          <form className="Formout">
            <Box
              className="FormInn"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "30ch" },
                display: "flex",
              }}
              noValidate
              autoComplete="off">
              <div>
                <div className="TextField">Application</div>
               
                <div className="primereactDropdown">
                  <Dropdown
                    value={"snowflake"}
                    filter={true}
                    
                    options={[{
                      label: "snowflake",
                      value: "snowflake",
                    }]}
                    dropdownIcon={<ArrowDropDownIcon fontSize="medium" />}
                    className="w-full"
                  />
                </div>
              </div>

              <div>
                <Button
                  variant="contained"
                  sx={{ marginTop: "35px" }}
                  startIcon={<QueryStatsIcon />}
                  type="submit">
                  view Queries
                </Button>
                <br /> <br />
              </div>
            </Box>
          </form>
        </div>
        {}
        <TableContainer component={Paper} style={design}>
          <TableContainer>
            <Table sx={{ minWidth: 2094 }} aria-label="customized table">
              <TableHead className="Mainheading">
                <TableRow>
                  <StyledTableCell align="right" style={colors}>
                    Execution Date
                  </StyledTableCell>
                  <StyledTableCell style={colors}>Query Text</StyledTableCell>
                  <StyledTableCell align="right" style={colors}>
                    Total Elapsed Time
                  </StyledTableCell>
                  <StyledTableCell align="right" style={colors}>
                    Role Name
                  </StyledTableCell>
                  <StyledTableCell align="right" style={colors}>
                    Database Name
                  </StyledTableCell>
                  <StyledTableCell align="right" style={colors}>
                    Schema Name
                  </StyledTableCell>
                  <StyledTableCell align="right" style={colors}>
                    Query Type
                  </StyledTableCell>

                  <StyledTableCell align="right" style={colors}>
                    Warehouse Name
                  </StyledTableCell>
                  <StyledTableCell align="right" style={colors}>
                    Execution Status
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roleData?.length ? (
                  roleData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <StyledTableRow
                        hover={true}
                        key={row.id}
                        onClick={() => setQuery(row.queryText)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        <StyledTableCell
                          align="right"
                          size="small"
                          style={color}>
                          {moment(row.startTime).format("YYYY-MMM-DD")}
                        </StyledTableCell>
                        <TableTrim
                          updatequery={updatequery}
                          style={color}
                          text={row.queryText}
                        />
                        <StyledTableCell
                          align="right"
                          size="small"
                          style={color}>
                          {Math.round(Number(row.totalElapsedTime) / 1000)}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          size="small"
                          style={color}>
                          {row.roleName}
                        </StyledTableCell>

                        <StyledTableCell
                          align="right"
                          size="small"
                          style={color}>
                          {row.databaseName}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          size="small"
                          style={color}>
                          {row.schemaName}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          size="small"
                          style={color}>
                          {row.queryType}
                        </StyledTableCell>

                        <StyledTableCell
                          align="right"
                          size="small"
                          style={color}>
                          {row.warehouseName}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          size="small"
                          style={color}>
                          {row.executionStatus}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell
                      className="novalue"
                      style={{
                        color: "rgb(225, 138, 24)",
                        fontSize: "large",
                        fontWeight: "700",
                      }}
                      align="right">
                      No Queries Found.
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[ 10, 50,100, { label: 'All', value: -1 }]}
              count={roleData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              // ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
            </Table>
          </TableContainer>
          
        </TableContainer>
        {}
        {loading && <CircularProgress color="inherit" />}

        <div className="Outerboxs">
          <Typography gutterBottom variant="h5" className="Innerboxs">
            Query Text
          </Typography>
          <Card>
            <Typography className="Innerbox">{query && query}</Typography>
          </Card>
        </div>
      </div>
    </>
  );
}


const TablePaginationActions=(props)=> {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }}
