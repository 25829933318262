import React from "react";

const DatasetDesc = ({originalString}) => {
  // patterns
  const patterns = [
    /Datasource,([\s\S]*?)( data catalog)/,
    // /Datasource,\s+(.*?)\s+data catalog/s,
    /data catalog,([\s\S]*?)( schema)/,
    /name{" "}([\s\S]*?)( from)/,
    /name([\s\S]*?)( from)/,
    /from{" "}([\s\S]*?)Datasource,/,
    /from([\s\S]*?)Datasource,/,
    /Datasource,{" "}([\s\S]*?)data catalog,/,
    /data catalog,{" "}([\s\S]*?)schema/,
    /and([\s\S]*?)tables/,
    /with identity ([\s\S]*?)./,
    /with identity (.*?)(\.)/,
    /with identity ([\s\S]*?)(AND|OR)/,
    /AND with([\s\S]*?)$/,
    /OR with([\s\S]*?)$/,
  ];

    let content = originalString
 
  patterns.forEach((pattern, index) => {
    
    const match = originalString.match(pattern);


    console.log(match, "match",pattern,'hhh');
    if (match) {
      const [fullMatch, before] = match;
      console.log(
        fullMatch,'before',typeof before,
        content.toString().split(fullMatch)[0],
        "content0",
        typeof content
      );
      content = `
          ${content.replace(before,`<strong>${before}</strong>`)}`
    }
  });

  console.log(content, "con");
  return <div dangerouslySetInnerHTML={{ __html: `<div>${content}</div>` }} />;
};

export default DatasetDesc;