import * as React from "react";
import { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./landingpage.css";
import axios from "axios";
import "react-tabs/style/react-tabs.css";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "@mui/material/Button";
import MUIDataTable from "mui-datatables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core/styles";
import { useOktaAuth } from "@okta/okta-react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ThreeMp } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

export default function DataTags(props) {
  // const { onClose, open, handleRevokeSubmit,actionType } = props;
  const useStyles = makeStyles((theme) => ({
    centeredHeader: {
      "& span": {
        display: "flex",

        justifyContent: "center",

        alignItems: "center",

        fontSize: "15px",

        fontWeight: "600",
      },
    },

    centeredrow: {
      "& span": {
        display: "flex",

        justifyContent: "center",

        alignItems: "center",

        fontSize: "15px",

        fontWeight: "400",
      },
    },
  }));
  const classes = useStyles();
  // console.log(props, "props");
  const Url = process.env.BASE_URL;
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;
  const { authState, oktaAuth } = useOktaAuth();

  const [userInfo, setUserInfo] = useState(null);
  const [identifiers, setIdentifiers] = useState([]);
  const [tabTitle, setTabTitle] = useState("Create");
  const [db, setDB] = useState([]);
  const [schema, setSchema] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editGlobalTag, setEditGlobalTag] = useState(false);

  const format = {
    global_tag_name: "",
    global_tag_description: "",
    global_tag_value_list: [],
    allowed_values: [],
    creater_email: "",
  };

  const saveFormat = {
    global_tag_name: "",
    tag_name: "",
    tag_allowed_value: "",
    tag_user_input_value: "",
    dataset_name: [],
    okta_email: "",
    identifiers: [],
    and_or: "",
    attribute: "",
    condition: "",
    user_text: "",
    is_table_level_tag: true,
    tag_type:"table",
    // is_column_level_tag: false,
  };

  const [modal, setModal] = useState(false);
  const [dataset, setDataset] = useState(false);
  const [tagNames, setTagNames] = useState([]);
  const [allData, setAllData] = useState([]);
  const [saveTag, setSaveTag] = useState(saveFormat);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [edit, setEdit] = useState(false);
  const [selectedTag, setSelectedTag] = useState({
    allowed_values: [null],
    Description: "",
    global_tag_name: "",
  });
  const [values, setValues] = useState(["1", "2"]);
  const [getTags, setGetTags] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [level, setLevel] = useState("table");
  const [editData, setEditData] = useState([]);
  const [tagInput, setTagInput] = useState([]);
  const [optionalInput, setOptionalInput] = useState([]);
  const [globalTagDetails, setGlobalTagDetails] = useState(format);
  const [tagName, setTagName] = useState("");
  const [getViewData, setGetViewData] = useState(false);
  // const [readData, setReadData] = useState({});
  const [read, setRead] = useState({});
  const [getGlobalTag, setGetGlobalTag] = useState(false);
  const [view, setView] = useState(false);
  const [tagDesc, setTagDesc] = useState(null);
  const [customModal, setCustomModal] = useState(false);
  const [nameWarning, setNameWarning] = useState(false);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]);

  const columns = [
    {
      name: "Tag Name",
      width: 300,
      filter: false,
    },
    {
      name: "Global Tag Name",
      width: 300,
      filter: false,
    },
    {
      name: "Tag Description",
      width: 600,
      filter: false,
    },
    {
      name: "Dataset Name",
      width: 600,
      filter: false,
    },
    {
      name: "Created By",
      width: 300,
      filter: false,
    },
  ];

  const covertStrSelect = (str) => {
    let arr = [];
    if (str == "") {
      return { label: "", value: "" };
    } else {
      arr.push(str);
      return getSelectVal(arr);
    }
  };
  // useEffect(() => {
  //   console.log("Selected Tag:", selectedTag);
  //   // Use selectedTag here or trigger other actions
  // }, [selectedTag]);

  const options = {
    selectableRowsHideCheckboxes: false,
    // onFilterChange: (changedColumn, filterList) => {
    //   console.log(changedColumn, filterList);
    // },
    onRowSelectionChange: (dataIndex, selectedRows, arr) => {
      if (arr.length != 0) {
        setSelectedRow(getTags[arr[0]]);
        // setEditData(getTags[arr[0]])
        // let obj = {
        //   tag_name: getTags[arr[0]][0],
        //   global_tag_name: getTags[arr[0]][1],
        //   tag_allowed_value: _.includes(getTags[arr[0]][5], "%")
        //     ? ""
        //     : getTags[arr[0]][5],
        //   tag_user_input_value: "",
        //   dataset_name: getTags[arr[0]][3],
        //   okta_email: getTags[arr[0]][4],
        //   identifiers: [],
        //   and_or: "",
        //   attribute: "",
        //   condition: "",
        //   user_text: "",
        //   is_table_level_tag: getTags[arr[0]][6],
        //   is_column_level_tag: getTags[arr[0]][7],
        // };
        // console.log(
        //   getTags[arr[0][5]],
        //   _.includes(getTags[arr[0][5]], "%"),
        //   "_.includes(getTags[arr[0][5]], " % ")"
        // );
        if (_.includes(getTags[arr[0][5]], "%")) {
          setTagInput(getTags[arr[0][5]]);
        }
        // obj.is_table_level_tag == true ? setLevel("table") : setLevel("column");
        // obj.is_table_level_tag == true ? setLevel(true) : setLevel(false);
        // console.log(obj, "arrrrr", getTags[arr[0]]);
        // setOptionalInput(obj.user_text);
        // setTagInput(obj.tag_user_input_value);
        // setSaveTag(obj);
        // setTagName(getTags[arr[0]][0]);
        // setTagDesc(getTags[arr[0]][2]);
        setShowEdit(true);
      } else {
        setShowEdit(false);
      }
    },
    customToolbarSelect: () => {},
    setRowProps: () => ({ className: classes.centeredrow }),
    selectableRows: "single",
    filterType: "multiselect",
    responsive: "scrollMaxHeight",
    tableBodyHeight: "60%",
    rowsPerPage: 30,
    download: false,
    filter: false,
    print: false,
    search: true,
    viewColumns: false,
    rowsPerPageOptions: [30, 60, 100],
    page: 0,
    textLabels: {
      body: {
        noMatch: isLoading ? "Loading the Data....." : "No data found",
      },
    },
  };

  const handleEdit = async (e, type) => {
    if (type == "view") {
      setView(true);
    }
    // console.log("inside edit", selectedRow[0]);
    let temp = { tag_name: selectedRow[0] };
    const encodedTagName = encodeURIComponent(temp.tag_name);
    let id = toast.loading("Please wait, Fetching data...");

  await  axios
      .get(Url + `get-dpds-load-tag?tag-name=${encodedTagName}`, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        // console.log(data, "selectedRow");
        toast.dismiss(id)
        toast.success( "Data loaded successfully",{autoClose:750})
        // toast.update(id, {
        //   render: "Data loaded successfully",
        //   type: "success",
        //   autoClose: 750,
        //   isLoading: false,
        // });

        delete data.data.is_disabled;
        delete data.data.created_timestamp;
        // setSaveTag(data.data);
        // data.data.global_tag_name = "get_get_test";
        setSaveTag(data.data);
        setTagDesc(selectedRow[2]);
        setTagName(data.data.tag_name);
        setOptionalInput(data.data.user_text);
        setTagInput(data.data.tag_user_input_value);

        // toast(_.capitalize(data.data.msg));
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(id)
        toast.error("Data loading failed.",{
          autoClose:1500
        })
        // toast.update(id, {
        //   render: "Data loading failed.",
        //   type: "error",
        //   autoClose: 5000,
        //   isLoading: false,
        // });
      });
  };

  const defaultVal = {
    and_or: [
      { label: "AND", value: "AND" },
      { label: "OR", value: "OR" },
    ],
    attribute: [
      { label: "Database Name", value: "table_catalog" },
      { label: "Schema Name", value: "table_schema" },
      { label: "Table Name", value: "table_name" },
      { label: "Column Name", value: "column_name" },
    ],
    condition: [
      { label: "Equals", value: "EQUALS" },
      { label: "Like", value: "LIKE" },
      { label: "In", value: "IN" },
    ],
  };

  const getSelectVal = (arr) => {
    let temp = [];
    _.map(arr, (i) => {
      let obj = {};
      obj.label = i;
      obj.value = i;
      temp.push(obj);
    });
    return temp;
  };

  const handleSelect = (e, type) => {
    let temp = _.cloneDeep(saveTag),
      check = [
        "attribute",
        "and_or",
        "condition",
        "user_text",
        "dataset_name",
        "tag_allowed_value",
        "tag_user_input_value",
      ];

    if (e.length && !_.includes(check, type)) {
      _.map(e, (i) => {
        if (!_.includes(temp[type], i.value)) {
          temp[type].push(i.value);
        }
        temp[type] = _.uniq(temp[type]);
      });
      // setDB(db);
    } else {
      temp[type] = [];
    }

    if (_.includes(check, type)) {
      temp[type] =
        type == "user_text" || type == "tag_user_input_value"
          ? e.target.value
          : e.value;
    }
    console.log(temp, "temp");
    setSaveTag(temp);
  };

  const fetchDataSets=async()=>{
    await axios
    .get(Url + `get-dpds-view-datasets`, {
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        "rum-svc-acct": Svc,
        "rum-api-key": API_KEY,
      },
    })
    .then((data) => {
      let database = [];
      if (data.data) {
        // setViewDataset(data.data);
        // data.data
        let arr = [];
        _.map(data.data.datasets, (i) => {
          arr.push(i[0]);
        });
        setDataset(getSelectVal(arr));
      }
    })
    .catch((err) => console.log(err));
  }

  useEffect(() => {
    // console.log(Url + `get-dpds-view-datasets`, "url");
    fetchDataSets()
  }, []);

  const getIdentifiers = (data) => {
    let temp = [];
    _.map(data, (i, index) => {
      // _.map(i.identifiers, (j) => {
      if (!_.includes(temp, i)) {
        temp.push(i);
      }
      // });
    });
    setIdentifiers(getSelectVal(_.uniq(temp)));
  };

  const fetchIdentifiers=async()=>{
   await axios
      .get(Url + `get-dpds-identifiers`, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        const data1 =
          data.data.identifier_list !== null
            ? data.data.identifier_list
            : "Currently No Identifers.";
        getIdentifiers(data1);
      })
      .catch((err) => console.log(err));

  }
  useEffect(() => {
    fetchIdentifiers()
      }, []);


  const fetchViewTags=async()=>{
    setIsLoading(true);
   await axios
    .get(Url + `get-dpds-view-tags`, {
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        "rum-svc-acct": Svc,
        "rum-api-key": API_KEY,
      },
    })
    .then((data) => {
      setIsLoading(false);
      setGetTags(data.data.tags);
      console.log(data.data, "llll");
    })
    .catch((err) => {
      setIsLoading(false);
      console.log(err);
    });
  }
  useEffect(() => {
    
    
    fetchViewTags()
   
  }, [getViewData]);

  const fetchGlobalTags=async()=>{

    await axios
      .get(Url + `get-dpds-view-global-tags`, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        // console.log(data.data, "data1");
        let name = [];
        _.map(data.data.global_tags, (i) => {
          name.push(i.global_tag_name);
        });
        setAllData(data.data.global_tags);
        setTagNames(getSelectVal(name));
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    console.log("Inside view global");
    fetchGlobalTags()
  }, [getGlobalTag]);

  const handleAddValue = (e) => {
    let temp = _.cloneDeep(values);
    temp.push("1");
    setValues(temp);
  };

  const handleRemoveValue = (e) => {
    if (values.length>1){

      let temp = _.cloneDeep(values);
      temp.pop();
      let allowed_values=globalTagDetails.global_tag_value_list
      allowed_values.pop()
      // setGlobalTagDetails({...globalTagDetails,allowed_values:allowed_values})
      setGlobalTagDetails({...globalTagDetails,global_tag_value_list:allowed_values})
      setValues(temp);
    }
  };

  const handleCancel = (e) => {
    setView(false);
    setSaveTag(saveFormat);
    setTagName("");
    setTagDesc(null);
    setGlobalTagDetails(format);
    setGetGlobalTag(false);
    setTabTitle("Create");
    setTagInput([]);

    setEdit(false);
  };

  const showConfirm = (par) => {
    if (par == "reset") {
      // console.log('reset clicked',par)
      setSaveTag({ ...saveTag, and_or: "" });
    }
    setCustomModal(!customModal);
  };

  const handleSaveTag =async (e) => {
    setTabTitle("Create");
    let id = toast.loading("Please wait, Working on storing data...");
    // console.log(saveTag, "llll");
    let temp = saveTag;
    if (temp.and_or != "") {
      if (
        [temp.attribute, temp.condition, temp.user_text].some((x) => x == "")
      ) {
        setCustomModal(true);

        return;
      }
    }
    temp.tag_name = tagName;
    await axios
      .post(Url + `dpds-create-tag`, temp, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        // console.log(data, "llll");
        toast.dismiss(id)
        toast.success("Data successfully Stored/Updated.",{autoClose:750})
        // toast.update(id, {
        //   render: "Data successfully Stored/Updated."+_.capitalize(data.data.msg),
        //   type: "success",
        //   autoClose: 750,
        //   isLoading: false,
        // });
        setGetViewData(!getViewData)
        setTabIndex(0)
        // toast(_.capitalize(data.data.msg));
      })
      .catch((err) => {console.log(err);
        toast.dismiss(id)
        toast.error("The data storage has failed.",{autoClose:1500})
        // toast.update(id, {
        //   render: "The data storage has failed.",
        //   type: "error",
        //   autoClose: 1000,
        //   isLoading: false,
        // });
      });
  };

  const handleSaveGlobalTag = async(e) => {
    let temp = globalTagDetails;
    setModal(false);
    temp.creater_email = userInfo.email;
    console.log(temp, "from global-tag");
   await axios
      .post(Url + `dpds-create-global-tag`, temp, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        toast((data.data?.status=='fail'?"Failed:":"")+_.capitalize(data.data.msg),{autoClose:1000});
        setGetGlobalTag(!getGlobalTag);
       
      })
      .catch((err) => console.log(err));
  };

  const handleGlobalTags = (e, type, index) => {
    let temp = _.cloneDeep(globalTagDetails);
    if (type == "allowed_values") {
      let arr = temp["global_tag_value_list"];
      arr[index] = e.target.value;
      temp[type] = arr;
    } else {
      temp[type] = e.target.value;
    }
    setGlobalTagDetails(temp);
  };

  const handleName = (e) => {
    let obj = _.find(allData, { global_tag_name: e.value });
    setEdit(false);
    setSelectedTag(obj);
    let temp = { ...saveTag };
    temp.global_tag_name = obj?.global_tag_name || '';
    temp.tag_allowed_value = obj?.allowed_values[0] || '';
    temp.okta_email = userInfo?.email || '';
    setSaveTag(temp);
};

// useEffect(() => {
//   },[selectedTag])

  const getTagValue = (val) => {
    let obj = _.find(allData, { global_tag_name: val });

    // let temp = {...saveTag};
    // temp.tag_allowed_value =
    //   obj.allowed_values[0] == null ? "" : obj.allowed_values[0];
    // temp.okta_email = userInfo.email;
    // setSelectedTag(obj);
    console.log(obj, "odj");
    let opti = getSelectVal(obj?.allowed_values);
    return opti;

    // return
  };

  const handleEditModal = () => {
    setEditGlobalTag(true);
    setModal(true);
    let obj = _.find(allData, { global_tag_name: saveTag.global_tag_name });
    obj['global_tag_description']=obj.description
    obj['global_tag_value_list']=obj.allowed_values
    console.log(obj, "handleEditModal");
    setValues(obj.allowed_values.map((item, index) => index.toString()));
    setGlobalTagDetails({
      ...obj,
    });
  };
  const handleRadio = (e) => {
    let temp = _.cloneDeep(saveTag);
    console.log(e.target.value, "tempppp");
    if (e.target.value == "is_table_level_tag") {
      temp[e.target.value] = true;
      temp["tag_type"] = "table";
      setLevel("table");
      // temp["is_column_level_tag"] = false;
    } else if((e.target.value == "is_schema_level_tag")) {
      // temp[e.target.value] = false;
      setLevel("schema");
      temp["is_table_level_tag"] = false;
      temp["tag_type"] = "schema";
    }else{
      setLevel("column");
      temp["is_table_level_tag"] = false;
      temp["tag_type"] = "column";

    }
    setSaveTag(temp);
    console.log(temp, "temp");
  };

  const handleInput = (e, type) => {
    let temp = saveTag;
    let value=e.target.value
    if (type == "tag_user_input_value") {
      setTagInput(value);
    } else if (type == "tag_name") {
      if (value.includes(" ")) {
        setNameWarning(true);
      } else {
        setNameWarning(false);
      }
      value = value.replace(/\s/g, "");
      setTagName(value);
    } else if (type == "tag_desc") {
      setNameWarning(false);
      setTagDesc(e.target.value);
    } else {
      setNameWarning(false);
      setOptionalInput(value);
    }
    temp[type] = value;
    setSaveTag(temp);
  };

  return (
    <div className="Outertext">
      {/* <img style={{ width: "100%" }} src={Soon}></img> */}
      <div className="dataset-cover">
        <div className="heading-dataset">
          <h4 className="dataset-head">Tag Management</h4>
        </div>
        <div className="tabs-cover">
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(tabIndex) => setTabIndex(tabIndex)}
          >
            <TabList style={{ cursor: "pointer" }}>
              <Tab
                onClick={(e) => {
                  setGetViewData(!getViewData),
                    setShowEdit(false),
                    handleCancel(e);
                }}
              >
                Tags
              </Tab>
              <Tab>{`${tabTitle} Tag`}</Tab>
            </TabList>
            <TabPanel>
              <div>
                
                <MUIDataTable
                  columns={columns}
                  data={getTags}
                  options={options}
                />
              </div>
              <div>
                <Button
                  className="dataset-button"
                  variant="contained"
                  size="large"
                  disabled={!showEdit }
                  onClick={(e) => {
                    if (selectedRow[10] == true) {
                      alert("The Tag is in Use and can’t be edited.");
                      // return;
                    }
                    setEdit(true), handleEdit(e), setTabIndex(1);
                    setTabTitle("Edit");
                  }}
                >
                  Edit Tag
                </Button>
                <Button
                  className="dataset-button"
                  variant="contained"
                  size="large"
              
                  onClick={(e) => {
                    setEdit(false), setTabIndex(1);
                    if (!identifiers.length){
                      fetchIdentifiers()
                    }
                  }}
                >
                  Create Tag
                </Button>
                <Button
                  className="dataset-button"
                  variant="contained"
                  size="large"
                  disabled={!showEdit}
                  onClick={(e) => {
                    handleEdit(e, "view");
                    // setShowEdit(true),
                    setTabIndex(1);
                    setTabTitle("View");
                  }}
                >
                  View Tag
                </Button>
              </div>
            </TabPanel>
            <TabPanel>
              {/* <div className="dataset-heading">
                <h4 className="tag-head">Tag Management</h4>
              </div> */}

              <>
                <div className="condition-inner">
                  <div className="condition-cover">
                    <div className="label-text select-in ">
                      <label>Tag Name :</label>
                    </div>
                    <div className="tag-name-input">
                      <input
                        placeholder="Enter Your Tag Name"
                        type="text"
                        className="form-control"
                        value={tagName}
                        id="usr"
                        disabled={view}
                        onChange={(e) => handleInput(e, "tag_name")}
                      />
                      {nameWarning && (
                        <h6
                          style={{
                            color: "orangered",
                            fontSize: "600",
                            margin: "5px 5px 0",
                            display: "flex",
                          }}
                        >
                          Space character not allowed!
                        </h6>
                      )}
                    </div>
                  </div>
                </div>
                <div className="condition-inner">
                  <div className="condition-cover">
                    <div className="label-text select-in">
                      <label>Tag Desc :</label>
                    </div>
                    <div className="tag-name-input">
                      <input
                        placeholder="Enter Your Tag Desc"
                        type="text"
                        className="form-control"
                        value={tagDesc}
                        id="usr"
                        disabled={view}
                        onChange={(e) => handleInput(e, "tag_desc")}
                        />
                    </div>
                  </div>
                </div>
                <div className="tags-cover">
                  <div className="label-text select-in">
                    <label>Global Tag :</label>
                  </div>
                  <div className="select-tags">
                    <Form.Group className="form-input col-sm">
                      {/* <label className="form-label form-label">Identifiers</label> */}
                      <Select
                        styles={{
                          control: () => ({
                            height: 33,
                            display: "flex",
                            borderRadius: "4px",
                            fontSize: 13,
                            border: "1px solid lightgray",
                            background: "white",
                          }),
                        }}
                        className="dropdownSizeSummary"
                        // defaultValue={}
                        // isMulti={true}
                        onChange={(e) => handleName(e)}
                        isDisabled={view }
                        value={
                          // ()=>{
                          // console.log(covertStrSelect(saveTag.global_tag_name),'line711',saveTag.global_tag_name)
                          covertStrSelect(saveTag.global_tag_name)

                          // }
                          //  {label : 'get_get_test', value : 'get_get_test'}
                        }
                        options={tagNames}
                        placeholder="Select Global Tag"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div className="button-dataset tag">
                    <button
                      type="button"
                      className="btn btn-dark"
                      disabled={view || saveTag.global_tag_name==''}
                      // disabled={view || selectedTag.global_tag_name==''}
                      style={{cursor:view?"not-allowed":'pointer'}}
                      onClick={(e) => handleEditModal(e)}
                      >
                      Edit Global Tag
                    </button>
                    <button
                      type="button"
                      disabled={view}
                      className="btn btn-dark"
                      style={{cursor:view?"not-allowed":'pointer'}}
                      onClick={(e) => setModal(true)}
                    >
                      Create Global Tag
                    </button>
                  </div>
                </div>
                <div className="radio-tag">
                  {/* <div className="label-text select-in"> */}
                  <div className="radio-container">
                    <div className="select-in">
                      {/* <label style={{width:"200px",marginLeft:"14px"}}>Apply Tag at :</label> */}
                      <label>Apply Tag at :</label>
                    </div>
                    <div className="label-radio">
                      {/* <div className="label-text select-in">
                    <label>Apply Tag at :</label>
</div> */}
                      <div style={{}}>
                        <input
                          type="radio"
                          id="table"
                          name="table"
                          value="is_table_level_tag"
                          onChange={(e) => handleRadio(e)}
                          checked={ saveTag.tag_type=="table"}
                          disabled={view}
                        />
                        <label className="label-button" htmlFor="table">
                          Table Level
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="column"
                          name="column"
                          value="is_column_level_tag"
                          checked={ saveTag.tag_type=="column"}
                          // checked={level=="column" || saveTag.tag_type=="column"}
                          onChange={(e) => handleRadio(e)}
                        />
                        <label className="label-button" htmlFor="column">
                          Column Level
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="schema"
                          name="schema"
                          value="is_schema_level_tag"
                          checked={saveTag.tag_type=="schema"}
                          onChange={(e) => handleRadio(e)}
                        />
                        <label className="label-button" htmlFor="schema">
                          Schema Level
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tags-cover">
                  <div className="label-text select-in">
                    <label>Tag Value :</label>
                  </div>
                  <div className="select-tags">
                    <Form.Group className="form-input col-sm">
                      {/* <label className="form-label form-label">Identifiers</label> */}
                      <Select
                        styles={{
                          control: () => ({
                            height: 33,
                            display: "flex",
                            borderRadius: "4px",
                            fontSize: 13,
                            border: "1px solid lightgray",
                            background:
                              selectedTag.allowed_values[0] == null
                                ? "#e9ecef"
                                : "white",
                          }),
                        }}
                        className="dropdownSizeSummary"
                        // defaultValue={}
                        isMulti={false}
                        onChange={(e) => handleSelect(e, "tag_allowed_value")}
                        isDisabled={
                          !selectedTag.allowed_values[0] == null || view
                        }
                        value={covertStrSelect(saveTag.tag_allowed_value)}
                        // options={getSelectVal(selectedTag.allowed_values)}
                        options={getTagValue(saveTag.global_tag_name)}
                        placeholder="Select Global Tag Value"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                
                    </Form.Group>
                  </div>
                  <div className="condition-inner-tag">
                    <div className="condition-cover">
                      {/* <div className="label-text select-in and-or"> */}
                      <div className="label-text">
                        <label>or</label>
                      </div>
                      <div className="select-tags tag-value">
                        <input
                          placeholder="Eg.%COMPANY%"
                          type="text"
                          className="form-control "
                          style={{ width: "300px" }}
                          value={tagInput}
                          // value={saveTag.tag_user_input_value}
                          disabled={
                            selectedTag.allowed_values[0] != null || view
                          }
                          id="usr"
                          onChange={(e) =>
                            handleInput(e, "tag_user_input_value")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tags-cover">
                  <div className="label-text select-in">
                    <label> Data Set :</label>
                  </div>
                  <div className="select-tags">
                    <Form.Group className="form-input col-sm">
                      {/* <label className="form-label form-label">Identifiers</label> */}
                      <Select
                        styles={{
                          control: () => ({
                            height: 33,
                            display: "flex",
                            borderRadius: "4px",
                            fontSize: 13,
                            border: "1px solid lightgray",
                            background: "white",
                          }),
                        }}
                        className="dropdownSizeSummary"
                        // defaultValue={}
                        isMulti={false}
                        onChange={(e) => handleSelect(e, "dataset_name")}
                        isDisabled={view}
                        value={covertStrSelect(saveTag.dataset_name)}
                        options={dataset}
                        placeholder="Select Dataset"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 condition-row">
                    <div className="condition-inner">
                      <div className="condition-cover">
                        <div className="label-text select-iden">
                          <label>Identifer :</label>
                        </div>
                        <div className="select-identifier">
                          <Form.Group className="form-input col-sm">
                            {/* <label className="form-label form-label">Identifiers</label> */}
                            <Select
                              styles={{
                                control: () => ({
                                  height: 33,
                                  display: "flex",
                                  borderRadius: "4px",
                                  fontSize: 13,
                                  border: "1px solid lightgray",
                                  background: "white",
                                  width: "120%",
                                }),
                              }}
                              className="dropdownSizeSummary"
                              // defaultValue={}
                              isMulti={true}
                              onChange={(e) => handleSelect(e, "identifiers")}
                              isDisabled={view}
                              value={getSelectVal(saveTag.identifiers)}
                              options={identifiers}
                              placeholder="rule/identity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="condition-end">
                        <div className="label-text select-and_or">
                          <label>AND/OR :</label>
                        </div>
                        <div className="select-identifier and_right">
                          <Form.Group className="form-input col-sm">
                            {/* <label className="form-label form-label">Identifiers</label> */}
                            <Select
                              styles={{
                                control: () => ({
                                  height: 33,
                                  display: "flex",
                                  borderRadius: "4px",
                                  fontSize: 13,
                                  border: "1px solid lightgray",
                                  background: "white",
                                  width: "120%",
                                }),
                              }}
                              className="dropdownSizeSummary"
                              isDisabled={view}
                              // defaultValue={}
                              onChange={(e) => handleSelect(e, "and_or")}
                              options={defaultVal.and_or}
                              value={covertStrSelect(saveTag.and_or)}
                              placeholder="AND/OR"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className="border-cover">
                      <div className="condition-inner">
                        <div className="condition-cover">
                          <div className="label-text select-attri">
                            <label>Attribute :</label>
                          </div>
                          <div className="select-identifier">
                            <Form.Group className="form-input col-sm">
                              {/* <label className="form-label form-label">Identifiers</label> */}
                              <Select
                                styles={{
                                  control: () => ({
                                    height: 33,
                                    display: "flex",
                                    borderRadius: "4px",
                                    fontSize: 13,
                                    border: "1px solid lightgray",
                                    width: "125%",
                                    // background: desc.and_or == "" ? "#e9ecef" : "white",
                                  }),
                                }}
                                className="dropdownSizeSummary"
                                onChange={(e) => handleSelect(e, "attribute")}
                                isDisabled={!saveTag.and_or || view}
                                options={defaultVal.attribute}
                                value={covertStrSelect(saveTag.attribute)}
                                placeholder="attribute"
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <div className="condition-end">
                          <div className="label-text select-condi">
                            <label>Condition : </label>
                          </div>
                          <div className="select-identifier">
                            <Form.Group className="form-input col-sm">
                              {/* <label className="form-label form-label">Identifiers</label> */}
                              <Select
                                styles={{
                                  control: () => ({
                                    height: 33,
                                    display: "flex",
                                    borderRadius: "4px",
                                    fontSize: 13,
                                    border: "1px solid lightgray",
                                    width: "125%",
                                    // background: desc.and_or == "" ? "#e9ecef" : "white",
                                  }),
                                }}
                                className="dropdownSizeSummary"
                                onChange={(e) => handleSelect(e, "condition")}
                                isDisabled={!saveTag.and_or || view}
                                options={defaultVal.condition}
                                value={covertStrSelect(saveTag.condition)}
                                placeholder="condition"
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className="condition-inner">
                        <div className="condition-cover">
                          <div className="label-text select-val">
                            <label>Value :</label>
                          </div>
                          <div className="input-value">
                            <input
                              placeholder="Eg.%COMPANY%"
                              type="text"
                              className="form-control "
                              value={saveTag.user_text}
                              disabled={!saveTag.and_or || view}
                              id="usr"
                              onChange={(e) => handleSelect(e, "user_text")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="optinal-inner">
                  <div className="col-md-3 select-identifier">

                </div>
                  </div> */}
                  </div>
                  {/* <div className="col-md-2 add-cover">
                  <div className="button-add">
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={(e) => handleAdd(e)}
                    >
                      Add
                    </button>
                  </div>
                </div> */}
                </div>
                <div className="button-cover">
                  {!view && (
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={(e) => handleSaveTag(e)}
                    >
                      {edit ? "Update" : "Save"}
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-dark"
                    onClick={(e) => {
                      handleCancel(e);
                      setTabIndex(0);
                    }}
                  >
                    {view ? (
                      <>
                        <ArrowBackOutlinedIcon /> Back
                      </>
                    ) : (
                      "Cancel"
                    )}
                  </button>
                </div>
              </>

              <Modal
                open={modal}
                onClose={(e) => {
                  setModal(false), setValues(["1", "2"]);
                }}
                showCloseIcon={false}
                center
              >
                <div className="modal-cover">
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      margin: "1rem",
                    }}
                  >
                    <h5>{editGlobalTag ? "Edit " : "Create "} Global Tag</h5>
                  </div>
                  <div className="condition-inner popup">
                    <div className="condition-popup">
                      <div className="label-text global-tag-modal-label">
                        <label>Global Tag Name :</label>
                      </div>
                      <div className="select-popup ">
                        <input
                          placeholder="Enter Global Tag Name"
                          type="text"
                          className="form-control "
                          value={globalTagDetails.global_tag_name}
                          // disabled={desc.and_or == ""}
                          id="usr"
                          onChange={(e) =>
                            handleGlobalTags(e, "global_tag_name")
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="condition-inner popup">
                    <div className="condition-popup">
                      <div className="label-text global-tag-modal-label">
                        <label>Global Tag Description :</label>
                      </div>
                      <div className="select-popup">
                        <input
                          placeholder="Enter Global Tag Description"
                          type="text"
                          className="form-control "
                          value={globalTagDetails.global_tag_description}
                          // disabled={desc.and_or == ""}
                          id="usr"
                          onChange={(e) => handleGlobalTags(e, "global_tag_description")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="label-text select-value">
                    <label>Allowed Values </label>
                  </div>
                  {_.map(values, (i, index) => {
                    return (
                      <div className="select-popupValue">
                        <input
                          placeholder="Enter Values"
                          type="text"
                          className="form-control "
                          value={
                            (Array.isArray(globalTagDetails.allowed_values) &&
                              globalTagDetails.allowed_values[index]) ||
                            null
                          }
                          // disabled={desc.and_or == ""}
                          id="usr"
                          onChange={(e) =>
                            handleGlobalTags(e, "allowed_values", index)
                          }
                        />
                      </div>
                    );
                  })}
                  <div className="button-cover">
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={(e) => handleAddValue(e)}
                    >
                      Add Value
                    </button>
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={(e) => handleRemoveValue(e)}
                    >
                      Remove Value
                    </button>
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={(e) => handleSaveGlobalTag(e)}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={(e) => {
                        setModal(false);
                        setGlobalTagDetails(format);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Modal>
            </TabPanel>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
          </Tabs>
          {customModal && (
            <Dialog
              open={customModal}
              onClose={() => setCustomModal(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle style={{ color: "black", fontSize: "16px" }}>
                {'Please complete additional condition based on "AND/OR".'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  - Click "Continue" to fill in required details.
                  <br />- Click "Reset" to clear the "AND/OR" selection.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => showConfirm("reset")}>Reset</Button>
                <Button onClick={() => showConfirm("continue")} autoFocus>
                  Continue
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
      </div>
    </div>
  );
}