import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "./landingpage.css";
import { useOktaAuth } from "@okta/okta-react";
import MUIDataTable from "mui-datatables";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SelectBox from "./SelectBox";
import DataTags from "./DataTags";
import Form from "react-bootstrap/Form";
import Select from "react-select";
// import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DatasetDesc from "./DatasetDesc";

export default function BasicTable() {
  const useStyles = makeStyles((theme) => ({
    centeredHeader: {
      "& span": {
        display: "flex",

        justifyContent: "center",

        alignItems: "center",

        fontSize: "15px",

        fontWeight: "600",
      },
    },

    centeredrow: {
      "& span": {
        display: "flex",

        justifyContent: "center",

        alignItems: "center",

        fontSize: "15px",

        fontWeight: "400",
      },
    },
  }));
  const classes = useStyles();

  const template = {
    dataset_name: "",
    database_names: [],
    data_source: "snowflake",
    schema_names: [],
    table_names: [],
    identifiers: [],
    condition: "",
    and_or: "",
    attribute: "",
    user_text: "",
    creater_email: "",
    is_save: false,
    description: "",
  };
  const Url = process.env.BASE_URL;
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;
  const { authState, oktaAuth } = useOktaAuth();
  const [tabIndex, setTabIndex] = useState(0);
  const [resData, setResData] = useState([]);
  const [db, setDB] = useState([]);
  const [schema, setSchema] = useState([]);
  const [allSchema, setAllSchema] = useState([]);
  const [tables, setTables] = useState([]);
  const [identifiers, setIdentifiers] = useState([]);
  const [datasetName, setDatasetName] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [customModal, setCustomModal] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [desc, setDesc] = useState(template);
  const [descStr, setDescStr] = useState("");
  const [viewSql, setViewSql] = useState({
    sql_query: "",
    show: false,
    table_list: "",
  });
  const [update, setUpdate] = useState(false);
  const [showDesc, setshowDesc] = useState(false);
  const [edit, setEdit] = useState(false);
  const [viewDataset, setViewDataset] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [editData, setEditData] = useState([]);
  const [getViewData, setGetViewData] = useState(false);
  const [tabTitle, setTabTitle] = useState("Create");
  const [isLoading, setIsLoading] = useState(true);
  const [addClicked, setAddClicked] = useState(false);
  const initialFetch = async () => {
    await axios
      .get(Url + `get-dpds-master-list`, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        // setIsLoading(false)
        let database = [];
        let dbName = Object.keys(data.data.dpds_master_list.dpds_database_name);
        setResData(data.data.dpds_master_list.dpds_database_name);

        let schemaArr = [];
        let tableArr = [];
        dbName.forEach((item) => {
          schemaArr.push(
            ...Object.keys(
              data.data.dpds_master_list.dpds_database_name[item][
                "dpds_schema_name"
              ]
            )
          );
          Object.keys(
            data.data.dpds_master_list.dpds_database_name[item][
              "dpds_schema_name"
            ]
          ).forEach((secItem) => {
            tableArr.push(
              ...data.data.dpds_master_list.dpds_database_name[item][
                "dpds_schema_name"
              ][secItem]
            );
          });
        });
        // tableArr = [...new Set(tableArr)]
        tableArr = [...new Set(tableArr)].sort();
        setSchema(getSelectVal(schemaArr));
        setTables(getSelectVal(tableArr));
        setDB(getSelectVal(dbName));
      })
      .catch((err) => console.log(err));

    await axios
      .get(Url + `get-dpds-identifiers`, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        const data1 =
          data.data.identifier_list !== null
            ? data.data.identifier_list
            : "Currently No Identifers.";
        getIdentifiers(data1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    initialFetch();
  }, []);

  const fetchViewData = async () => {
    setIsLoading(true);
    await axios
      .get(Url + `get-dpds-view-datasets`, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        setIsLoading(false);

        if (data.data) {
          setViewDataset(data.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    fetchViewData();
  }, [getViewData]);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]);

  const getSelectVal = (arr) => {
    // debugger
    let temp = [];
    _.map(arr, (i) => {
      let obj = {};
      obj.label = i;
      obj.value = i;
      temp.push(obj);
    });
    return temp;
  };

  const covertStrSelect = (str) => {
    let arr = [];
    if (str == "") {
      return { label: "", value: "" };
    } else {
      arr.push(str);
      return getSelectVal(arr);
    }
  };

  const defaultVal = {
    and_or: [
      { label: "AND", value: "AND" },
      { label: "OR", value: "OR" },
    ],

    //static values for backend
    attribute: [
      { label: "Database Name", value: "table_catalog" },
      { label: "Schema Name", value: "table_schema" },
      { label: "Table Name", value: "table_name" },
      { label: "Column Name", value: "column_name" },
    ],
    condition: [
      { label: "Equals", value: "Equals" },
      { label: "Like", value: "Like" },
      { label: "In", value: "In" },
    ],
  };

  const columns = [
    {
      name: "Data Catalog",
      width: 300,
      filter: false,
    },
    {
      name: "Schema",
      width: 300,
      filter: false,
    },
    {
      name: "Table",
      width: 300,
      filter: false,
    },
    {
      name: "Column Name",
      width: 300,
      filter: false,
    },
    // {
    //   name: "Identifiers",
    //   width: 300,
    // },
    // {
    //   name: "PII Column Count",
    //   width: 300,
    // },
  ];

  const options = {
    selectableRowsHideCheckboxes: true,
    setRowProps: () => ({ className: classes.centeredrow }),
    selectableRows: "single",
    filterType: "multiselect",
    responsive: "scrollMaxHeight",
    tableBodyHeight: "60%",
    rowsPerPage: 30,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    rowsPerPageOptions: [30, 60, 100],
    page: 1,
  };

  const options2 = {
    selectableRowsHideCheckboxes: false,
    onRowSelectionChange: (dataIndex, selectedRows, arr) => {
      if (arr.length != 0) {
        setSelectedRow(viewDataset.datasets[arr[0]]);
        setShowEdit(true);
      } else {
        setShowEdit(false);
      }
    },
    customToolbarSelect: () => {},
    setRowProps: () => ({ className: classes.centeredrow }),
    selectableRows: "single",
    filterType: "multiselect",
    responsive: "scrollMaxHeight",
    tableBodyHeight: "60%",
    rowsPerPage: 30,
    download: false,
    filter: false,
    print: false,
    search: true,
    viewColumns: false,
    rowsPerPageOptions: [30, 60, 100],
    page: 0,
    textLabels: {
      body: {
        noMatch: isLoading ? "Loading the Data....." : "No data found",
      },
    },
  };

  const handleViewCancel = (e, status) => {
    let temp = viewSql;
    temp.show = false;
    setViewSql(temp);
    setDesc(template);
    setDescStr("");
    // status !== undefined && toast(status);
    setUpdate(!update);
  };

  const getIdentifiers = (data) => {
    let temp = [];
    _.map(data, (i, index) => {
      if (!_.includes(temp, i)) {
        temp.push(i);
      }
    });
    setIdentifiers(getSelectVal(_.uniq(temp)));
  };

  const handleSelect = (e, index, type) => {
    let temp = desc,
      optional = ["attribute", "and_or", "condition", "user_text"];

    if (type == "database_names") {
      let schema = [],
        allSchema = [];
      _.map(e, (i, index) => {
        if (index > 0) {
          // console.log(res,'resData')
          schema = getSelectVal(
            Object.keys(resData[i.value].dpds_schema_name)
          ).concat(schema);
          allSchema.push(resData[i.value].dpds_schema_name);
        } else {
          schema = getSelectVal(Object.keys(resData[i.value].dpds_schema_name));
          allSchema.push(resData[i.value].dpds_schema_name);
        }
      });
      setAllSchema(allSchema);
      setSchema(schema);
    } else if (type == "schema_names") {
      let tables = [],
        temp = [];

      _.map(e, (i, index) => {
        _.map(allSchema, (j) => {
          j[i.value] ? _.map(j[i.value], (k) => temp.push(k)) : "";
        });
        tables = getSelectVal(temp);
      });

      setTables(tables);
    }
    if (e.length && !_.includes(optional, type)) {
      temp[type] = [];
      _.map(e, (i) => {
        if (!_.includes(temp[type], i.value)) {
          temp[type].push(i.value);
        }

        temp[type] = _.uniq(temp[type]);
      });
      // setDB(db);
    } else {
      temp[type] = [];
    }

    if (_.includes(optional, type)) {
      temp[type] = type == "user_text" ? e.target.value : e.value;
    }

    setDesc(temp);
    setUpdate(!update);
  };

  const handleName = (e) => {
    setDatasetName(e.target.value);
  };

  const handleAdd = () => {
    //     if (desc.dataset_name == "") {
    //       toast("Please Enter Dataset Name",{
    // autoClose:1000
    //       });
    //       return
    //     }

    setAddClicked(true);
    let optStrstr = "",
      optStr = "",
      dbstr = [],
      idenstr = [],
      str,
      temp = desc;

    if (temp.and_or != "") {
      if (
        [temp.attribute, temp.condition, temp.user_text].some((x) => x == "")
      ) {
        setCustomModal(true);
        // showConfirm('true')
        return;
      }
    }

    setshowDesc(true);

    _.map(desc.database_names, (i, index) => {
      if (desc.schema_names.length) {
        _.map(desc.schema_names, (j) => {
          if (_.includes(Object.keys(resData[i].dpds_schema_name), j)) {
            if (desc.table_names.length) {
              _.map(desc.table_names, (h) => {
                if (_.includes(allSchema[j], h)) {
                  dbstr.push(i + "." + j + "_" + h);
                }
              });
            } else {
              dbstr.push(i + "." + j);
            }
          } else {
            dbstr.push(i);
          }
        });
      } else {
        dbstr.push(i);
      }
    });

    if (desc.identifiers.length) {
      _.map(desc.identifiers, (i) => {
        idenstr.push(i);
      });
    }

    str =
      `Create a dataset with name {"${datasetName}"} ` +
      ` from {"Snowflake"}  Datasource, {"${
        desc.database_names.length == 0 ? "ALL" : desc.database_names.join(",")
      } "} ` +
      ` data catalog,{"${
        desc.schema_names.length == 0 ? "ALL" : desc.schema_names.join(",")
      }"}` +
      ` schema and {"${
        desc.table_names.length == 0 ? "ALL" : desc.table_names.join(",")
      }"} ` +
      "tables" +
      (desc.identifiers.length > 0 ? " with identity " : "") +
      (desc.identifiers.length > 0 ? idenstr.join(",") : "") +
      ".";
    // str = (
    //   <div className="desc-set">
    //     <p>
    //       Create a dataset with name{" "}
    //       <span className="bold-text">{datasetName}</span> from{" "}
    //       <span className="bold-text">Snowflake</span> Datasource,{" "}
    //       <span className="bold-text">
    //         {desc.database_names.length == 0
    //           ? "ALL"
    //           : desc.database_names.join(",")}
    //       </span>{" "}
    //       data catalog,{" "}
    //       <span className="bold-text">
    //         {desc.schema_names.length == 0
    //           ? "ALL"
    //           : desc.schema_names.join(",")}
    //       </span>{" "}
    //       schema and{" "}
    //       <span className="bold-text">
    //         {desc.table_names.length == 0 ? "ALL" : desc.table_names.join(",")}
    //       </span>{" "}
    //       tables
    //       {desc.identifiers.length > 0 && " with identity "}
    //       {desc.identifiers.length > 0 && (
    //         <span className="bold-text">{idenstr.join(",")}</span>
    //       )}
    //     </p>
    //   </div>
    // );

    if (desc.and_or) {
      optStr =
        // <div className="desc-set">
        //   <p>
        //     Create a dataset with name{" "}+
        //     <span className="bold-text">{datasetName}</span> from{" "}
        //     <span className="bold-text">Snowflake</span> Datasource,{" "}
        //     <span className="bold-text">{desc.database_names.join(",")}</span>{" "}
        //     data catalog,{" "}
        //     <span className="bold-text">
        //       {desc.schema_names.length == 0
        //         ? "ALL"
        //         : desc.schema_names.join(",")}
        //     </span>{" "}
        //     schema and{" "}
        //     <span className="bold-text">
        //       {desc.table_names.length == 0
        //         ? "ALL"
        //         : desc.table_names.join(",")}
        //     </span>{" "}
        //     tables {desc.identifiers.length ? " with identity " : ""}
        //     {desc.identifiers.length && (
        //       <span className="bold-text">{idenstr.join(",")}</span>
        //     )}{" "}
        //     <span className="bold-text">{desc.and_or}</span>{" "}
        //     <span className="bold-text">
        //       {desc.attribute ? "with " + desc.attribute : ""}
        //     </span>{" "}
        //     <span className="bold-text">
        //       {desc.condition ? desc.condition : ""}
        //     </span>{" "}
        //     <span className="bold-text">
        //       {desc.user_text ? desc.user_text : ""}
        //     </span>
        //   </p>
        // </div>
        // <div className="desc-set">
        //   <p>
        `Create a dataset with name {"${datasetName}"} ` +
        ` from {"Snowflake"}  Datasource, {"${
          desc.database_names.length == 0
            ? "ALL"
            : desc.database_names.join(",")
        } "} ` +
        ` data catalog,{"${
          desc.schema_names.length == 0 ? "ALL" : desc.schema_names.join(",")
        }"}` +
        ` schema and {"${
          desc.table_names.length == 0 ? "ALL" : desc.table_names.join(",")
        }"} ` +
        "tables" +
        (desc.identifiers.length > 0 ? " with identity " : "") +
        (desc.identifiers.length > 0 ? idenstr.join(",") : "") +
        ` ${desc.and_or} ` +
        (desc.attribute ? "with " + desc.attribute : "") +
        (desc.condition ? " " + desc.condition : "") +
        (desc.user_text ? " " + desc.user_text : "");
    }

    let finalDesc = `Create a dataset with name ${datasetName} from Snowflake Datasource,{" "}
          ${
            desc.database_names.length == 0
              ? "ALL"
              : desc.database_names.join(",")
          }${" "}
          data catalog,${" "}
          ${
            desc.schema_names.length == 0 ? "ALL" : desc.schema_names.join(",")
          }${" "}
          schema and${" "}
          ${
            desc.table_names.length == 0 ? "ALL" : desc.table_names.join(",")
          }{" "}
          tables
          ${desc.identifiers.length > 0 && " with identity "}
          ${desc.identifiers.length > 0 && idenstr.join(",")}`;

    if (desc.and_or) {
      finalDesc =
        finalDesc +
        `${" "}
            ${desc.and_or}{" "}
              ${desc.attribute ? "with " + desc.attribute : ""}
            ${" "}
              ${desc.condition ? desc.condition : ""}
            ${" "}
              ${desc.user_text ? desc.user_text : ""}`;
    }

    temp.description = finalDesc;
    temp.identifiers = idenstr;
    setDescStr(desc.and_or ? optStr : str);
    setDesc(temp);
  };

  const data = {
    datasets: [
      [
        "testdataset8",
        "Create a dataset with name TEST from Snowflake Datasource, ALL data catalog, ALL schema and ALL tables",
        "sindhu.kandula@procore.com",
      ],
      [
        "testdataset9",
        "Create a dataset with name TEST from Snowflake Datasource, ADMIN data catalog, PDP_DATA_GOVERNANCE schema and ALL tables",
        "sindhu.kandula@procore.com",
      ],
      [
        "test",
        "Create a dataset with name TEST from Snowflake Datasource, FOO_DB data catalog, SCHEMA_FINSYS_TEST schema and MY_THIRD_DBT_MODEL tables",
        "sindhu.kandula@procore.com",
      ],
    ],
  };

  const testData = {
    dataset_name: "test dataset",
    data_source: "snowflake",
    database_names: ["ADMIN"],
    schema_names: ["EXAMPLE"],
    table_names: ["TEST_123"],
    identifiers: ["EMAIL"],
    and_or: "",
    attribute: "",
    condition: "",
    user_text: "",
    sql: "",
    table_preview: [
      ["PDP_MDM", "BCI_PREP", "MDM_COMPANIES_ASIA_PREP"],
      ["PDP_MDM", "BCI_PREP", "MDM_COMPANIES_AUSTRALIA_PREP"],
    ],
  };

  const columns2 = [
    {
      name: "Dataset Name",
      width: 300,
      filter: false,
    },
    {
      name: "Description",
      width: 600,
      filter: false,
    },
    {
      name: "Created By",
      width: 300,
      filter: false,
    },
  ];

  const getDatasetDetails = async (e, val) => {
    await axios
      .get(
        Url + `get-dpds-view-dataset-detail?dataset-name=${selectedRow[0]}`,
        {
          headers: {
            "rum-svc-acct": Svc,
            "rum-api-key": API_KEY,
          },
        }
      )
      .then((data) => {
        let database = [];

        if (data.data) {
          let res = data.data;

          setDatasetName(res.dataset_name);
          setViewSql({
            sql_query: res.sql,
            show: val,
            table_list: res.table_preview,
          });
          //This callback handleSelect function will trigger after state variable updated.

          // console.log(res);
          setDesc(res, () => {
            handleSelect(
              getSelectVal(res.database_names),
              null,
              "database_names"
            );
            if (res.schema_names.length > 0) {
              handleSelect(
                getSelectVal(res.schema_names),
                null,
                "schema_names"
              );
            } else if (res.table_names.length > 0) {
              handleSelect(getSelectVal(res.table_names), null, "table_names");
            }
          });
          // handleSelect(
          //   getSelectVal(res.database_names),
          //   null,
          //   "database_names"
          // );
          // if (res.schema_names.length > 0) {
          //   debugger

          //   handleSelect(getSelectVal(res.schema_names), null, "schema_names");
          // } else if (res.table_names.length > 0) {
          //   handleSelect(getSelectVal(res.table_names), null, "table_names");
          // }
          setDescStr(res.description);
          setTabIndex(1);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCancel = (e) => {
    // debugger
    setDatasetName("");
    setAddClicked(false);
    setTabTitle("Create");
    setDescStr("");
    setDesc(template);
    setEdit(false);
  };

  const showConfirm = (par) => {
    if (par == "reset") {
      setDesc({ ...desc, and_or: "" });
    }
    setCustomModal(!customModal);
  };

  
  const handleSave = async (e, save) => {
    let temp = desc;
    if (edit){
      temp={...template,...desc}
    }
    if (save == false) {
      if (temp.and_or != "") {
        if (
          [temp.attribute, temp.condition, temp.user_text].some((x) => x == "")
        ) {
          setCustomModal(true);
          // showConfirm('true')
          return;
        }
      }
    }

    temp.is_save = save ? "True" : "False";
    temp.creater_email = userInfo.email;
    temp.dataset_name = datasetName;
    temp.description = descStr;

    setDesc(temp);

    if (desc.dataset_name == "") {
      toast("Please Enter Dataset Name");
    } else {
      let id = toast.loading("Please wait, Working on storing data...");
      
      //debugger
      await axios
        .post(Url + `dpds-create-dataset`, temp, {
          headers: {
            "rum-svc-acct": Svc,
            "rum-api-key": API_KEY,
          },
        })
        .then((data) => {
          toast.dismiss(id)
        
          let toastMsg= "Dataset Saved Succesfully"
       
         
          if (data.data) {
            let temp = viewSql;
            setViewSql({
              sql_query: data.data.sql_query,
              show: true,
              table_list: data.data.table_list,
            });
            save &&
              handleViewCancel(
                e,
                data.data.status == "fail"
                  ? data.data.error
                  : "Dataset Saved Succesfully"
              );
            setUpdate(!update);
            if (data.data.status == "fail"  ){

              toastMsg= data.data.error 
            }
          }
          toast.success(toastMsg,{autoClose:750})
        
          if (save) {
            setTabIndex(0);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.dismiss(id)
          toast.error("The data storage has failed.",{autoClose:1500})
          
        });
        // toast.dismiss()
    }
  };

  return (
    <>
      <div className="Outertext">
        <div className="dataset-cover">
          <div className="heading-dataset">
            <h4 className="dataset-head">Dataset Management</h4>
          </div>
          <div className="tabs-cover">
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(tabIndex) => setTabIndex(tabIndex)}
            >
              <TabList>
                <Tab
                  onClick={(e) => {
                    setShowEdit(false),
                      handleCancel(e),
                      setGetViewData(!getViewData);
                  }}
                >
                  Datasets
                </Tab>
                {}
                <Tab
                  onClick={(e) => {
                    setEdit(false);
                    setViewSql({ sql_query: "", show: false, table_list: "" });
                    setDescStr("");
                    setGetViewData(false);
                  }}
                >
                  {`${tabTitle} Dataset`}
                </Tab>
              </TabList>

              <TabPanel>
                <div>
                  <MUIDataTable
                    columns={columns2}
                    data={viewDataset.datasets}
                    options={options2}
                  />
                </div>
                <div>
                  <Button
                    className="dataset-button"
                    variant="contained"
                    size="large"
                    disabled={!showEdit}
                    onClick={(e) => {
                      if (selectedRow[6] == true) {
                        alert("The Dataset is in Use and can’t be edited.");
                        return;
                      }
                      setGetViewData(false);
                      setTabTitle("Edit");
                      setEdit(true);
                      setTabIndex(1);
                      getDatasetDetails(e, false);
                    }}
                  >
                    Edit Dataset
                  </Button>
                  <Button
                    className="dataset-button"
                    variant="contained"
                    size="large"
                    onClick={(e) => {
                      // setEdit(false), setTabIndex(1);
                      setGetViewData(false);
                      setEdit(false);
                      setTabIndex(1);
                    }}
                  >
                    Create Dataset
                  </Button>
                  <Button
                    className="dataset-button"
                    variant="contained"
                    size="large"
                    disabled={!showEdit}
                    onClick={(e) => {
                      setGetViewData(true);
                      setTabTitle("View");
                      getDatasetDetails(e, true);
                    }}
                  >
                    View Dataset
                  </Button>
                </div>
              </TabPanel>
              <TabPanel>
                {!viewSql.show ? (
                  <div>
                    <div className="form-group">
                      <label className="dataset-label">Dataset Name</label>
                      <input
                        placeholder="Enter Dataset Name"
                        type="text"
                        className="form-control dataset-name"
                        id="usr"
                        value={datasetName}
                        required
                        onChange={(e) => handleName(e)}
                      />
                    </div>
                    <div>
                      <label className="dataset-label">
                        Where do you want to want to select the dataset from?
                      </label>
                    </div>
                    <div className="dataset-box">
                      <SelectBox
                        tableClass="small-table"
                        label={false}
                        placeholder={[
                          "Select Datasource",
                          "Select Data Catalog",
                          "Select Schema",
                          "Select Table",
                        ]}
                        db={db}
                        handleSelect={handleSelect}
                        schema={schema}
                        tables={tables}
                        desc={desc}
                        schemaValue={getSelectVal(
                          // edit ? editData.schema_names :
                          desc.schema_names
                        )}
                        // identifiersValue={getSelectVal(edit ? editData.identifiers : desc.identifiers)}
                        databaseValue={getSelectVal(
                          // edit ? editData.database_names :
                          desc.database_names
                        )}
                        tableValue={getSelectVal(
                          // edit ? editData.table_names :
                          desc.table_names
                        )}
                      />
                     
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <label className="dataset-label">
                        How do you want this dataset to select the tables?
                        (Optional)
                      </label>
                      <div className="add-cover">
                        <div className="button-add">
                          <button
                            type="button"
                            className="btn btn-dark"
                            onClick={(e) => handleAdd(e)}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-10 condition-row">
                        <div className="condition-inner">
                          <div className="condition-cover">
                            <div className="label-text select-iden">
                              <label>Identifier :</label>
                            </div>
                            <div className="select-identifier">
                              <Form.Group className="form-input col-sm">
                                <Select
                                  styles={{
                                    control: () => ({
                                      height: 33,
                                      display: "flex",
                                      borderRadius: "4px",
                                      fontSize: 13,
                                      border: "1px solid lightgray",
                                      background: "white",
                                    }),
                                  }}
                                  className="dropdownSizeSummary"
                                  // defaultValue={}
                                  isMulti={true}
                                  onChange={(e) => {
                                    handleSelect(e, "iden", "identifiers");
                                  }}
                                  // isDisabled={desc.database_names.length == 0}
                                  value={getSelectVal(
                                    // edit
                                    //   ? editData.identifiers
                                    //   :
                                    desc.identifiers
                                  )}
                                  options={identifiers}
                                  placeholder="rule/identity"
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="condition-end">
                            <div className="label-text select-and_or">
                              <label>AND/OR :</label>
                            </div>
                            <div className="select-identifier and_right">
                              <Form.Group className="form-input col-sm">
                                <Select
                                  styles={{
                                    control: () => ({
                                      height: 33,
                                      display: "flex",
                                      borderRadius: "4px",
                                      fontSize: 13,
                                      border: "1px solid lightgray",
                                      background: "white",
                                    }),
                                  }}
                                  className="dropdownSizeSummary"
                                  // isDisabled={desc.database_names.length == 0}
                                  // defaultValue={}
                                  onChange={(e) => handleSelect(e, 0, "and_or")}
                                  options={defaultVal.and_or}
                                  value={covertStrSelect(desc.and_or)}
                                  placeholder="AND/OR"
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                        <div className="border-cover">
                          <div className="condition-inner">
                            <div className="condition-cover">
                              <div className="label-text select-attri">
                                <label>Attribute :</label>
                              </div>
                              <div className="select-identifier">
                                <Form.Group className="form-input col-sm">
                                  <Select
                                    styles={{
                                      control: () => ({
                                        height: 33,
                                        display: "flex",
                                        borderRadius: "4px",
                                        fontSize: 13,
                                        border: "1px solid lightgray",
                                        background:
                                          desc.and_or == ""
                                            ? "#e9ecef"
                                            : "white",
                                      }),
                                    }}
                                    className="dropdownSizeSummary"
                                    onChange={(e) =>
                                      handleSelect(e, 0, "attribute")
                                    }
                                    isDisabled={desc.and_or == ""}
                                    options={defaultVal.attribute}
                                    value={covertStrSelect(desc.attribute)}
                                    placeholder="attribute"
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            <div className="condition-end">
                              <div className="label-text select-condi">
                                <label>Condition : </label>
                              </div>
                              <div className="select-identifier">
                                <Form.Group className="form-input col-sm">
                                  <Select
                                    styles={{
                                      control: () => ({
                                        height: 33,
                                        display: "flex",
                                        borderRadius: "4px",
                                        fontSize: 13,
                                        border: "1px solid lightgray",
                                        background:
                                          desc.and_or == ""
                                            ? "#e9ecef"
                                            : "white",
                                      }),
                                    }}
                                    className="dropdownSizeSummary"
                                    onChange={(e) =>
                                      handleSelect(e, 0, "condition")
                                    }
                                    isDisabled={desc.and_or == ""}
                                    options={defaultVal.condition}
                                    value={covertStrSelect(desc.condition)}
                                    placeholder="condition"
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                          <div className="condition-inner">
                            <div className="condition-cover">
                              <div className="label-text select-val">
                                <label>Value :</label>
                              </div>
                              <div className="select-identifier">
                                <input
                                  placeholder="Eg.%COMPANY%"
                                  type="text"
                                  className="form-control "
                                  value={desc.user_text}
                                  disabled={desc.and_or == ""}
                                  id="usr"
                                  onChange={(e) =>
                                    handleSelect(e, 0, "user_text")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="dataset-label">Description</label>
                      <div className="border-cover readOnly">
                        <DatasetDesc originalString={descStr} />
                      </div>
                      {/* <div className="border-cover readOnly">{tabTitle=="Create"?descStr:addClicked?descStr:<DatasetDesc originalString={descStr}/>}</div> */}
                    </div>
                    <div className="button-cover">
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={(e) => handleSave(e, false)}
                      >
                        Next
                      </button>
                      {!edit && (
                        <button
                          type="button"
                          className="btn btn-dark"
                          onClick={(e) => handleCancel(e)}
                        >
                          Reset
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={(e) => {
                          setTabIndex(0);
                          handleCancel(e);
                        }}
                        // onClick={()=>setViewSql({...viewSql,show:false})}
                      >
                        Cancel
                      </button>
                    </div>
                    <ToastContainer
                      position="top-center"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="dark"
                    />
                  </div>
                ) : (
                  <div className="form-group">
                    <label className="dataset-label">Description</label>
                    <div className="border-cover readOnly">{descStr}</div>
                    <label className="dataset-label">
                      SQL Execution Statement
                    </label>
                    <div className="border-cover readOnly">
                      <p>{viewSql.sql_query}</p>
                    </div>
                    <div className="dataset-table">
                      <MUIDataTable
                        columns={columns}
                        data={viewSql.table_list}
                        options={options}
                      />
                    </div>
                    <div className="button-cover">
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={(e) => {
                          setTabIndex(0);
                          setTabTitle("Create");
                          setViewSql({ ...viewSql, show: false });
                          handleCancel(e);
                        }}
                      >
                        <ArrowBackOutlinedIcon /> Back
                      </button>
                      {/* <button
                        type="button"
                        className="btn btn-dark"
                        // onClick={(e) => handleSave(e, false)}
                      >
                        Next
                      </button> */}
                      {!getViewData && (
                        <button
                          type="button"
                          className="btn btn-dark"
                          onClick={(e) => {
                            handleSave(e, true);
                            setTabTitle("Create");
                          }}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </TabPanel>
            </Tabs>
            {customModal && (
              <Dialog
                open={customModal}
                onClose={() => setCustomModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle style={{ color: "black", fontSize: "16px" }}>
                  {'Please complete additional condition based on "AND/OR".'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    - Click "Continue" to fill in required details.
                    <br />- Click "Reset" to clear the "AND/OR" selection.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => showConfirm("reset")}>Reset</Button>
                  <Button onClick={() => showConfirm("continue")} autoFocus>
                    Continue
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </div>
        </div>
      </div>
    </>
  );
}