import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import "./landingpage.css";

import moment from "moment";
import Link from "@mui/material/Link";

function RoleTableRow(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      {row?.userId ? (
        <>
          {row.personas.map((ddo, index) => (
            <React.Fragment key={index}>
              <TableRow sx={{ "& > *": { borderBottom: "unset" } }} hover>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(open === index ? -1 : index)}>
                    {open === index ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell
                  scope="row"
                  style={{
                    color: "rgb(225, 138, 24)",
                    fontSize: "unset",
                    fontWeight: "500",
                  }}>
                  {" "}
                  <Link
                    color="rgb(225, 138, 24)"
                    href={ddo.jiraUrl}
                    target="_blank">
                    {ddo.rumReference}
                  </Link>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: "rgb(225, 138, 24)",
                    fontSize: "unset",
                    fontWeight: "500",
                  }}>
                  {ddo.personaName}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: "rgb(225, 138, 24)",
                    fontSize: "unset",
                    fontWeight: "500",
                  }}>
                  {ddo.status}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: "rgb(225, 138, 24)",
                    fontSize: "unset",
                    fontWeight: "500",
                  }}>
                  {moment(ddo.expiration_ts).isValid()
                    ? moment(ddo.expiration_ts).format("YYYY-MMM-DD")
                    : null}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: "rgb(225, 138, 24)",
                    fontSize: "unset",
                    fontWeight: "500",
                  }}>
                  {moment(ddo.creation_ts).format("YYYY-MMM-DD")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}>
                  <Collapse in={open === index} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{
                                color: "#FFFFFF",
                                fontSize: "medium",
                                fontWeight: "500",
                              }}>
                              APPLICATION NAME
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: "#FFFFFF",
                                fontSize: "medium",
                                fontWeight: "500",
                              }}>
                              APPLICATION ROLE NAME
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ddo &&
                            ddo.groups.map(dd => (
                              <TableRow key={ddo.personaId}>
                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{
                                    color: "rgb(225, 138, 24)",
                                    fontSize: "medium",
                                    fontWeight: "500",
                                  }}>
                                  {dd.serviceName}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{
                                    color: "rgb(225, 138, 24)",
                                    fontSize: "medium",
                                    fontWeight: "500",
                                  }}>
                                  {dd.serviceRoleName}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </>
      ) : (
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell
            align="right"
            style={{
              color: "rgb(225, 138, 24)",
              fontSize: "large",
              fontWeight: "700",
              display: "flex",
              marginLeft: "40px",
            }}>
            No Roles Assigned Currently.
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default function RumadminRoleTable({ roleData }) {
  return (
    <div className="Outerbox">
      <TableContainer
        component={Paper}
        style={{ border: "solid", borderColor: "#ffff", borderWidth: "3px" }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell
                align="center"
                style={{
                  color: "WHITE",
                  fontSize: "large",
                  fontWeight: "500",
                }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell
              style={{
                color: "rgb(225, 138, 24)",
                fontSize: "large",
                fontWeight: "700",
              }}>
              No Cost Assigned Currently.
            </TableCell>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
