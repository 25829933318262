import React from "react";
import Box from "@mui/material/Box";
import "./admin.css";

export default function Admin() {
  return (
    <div>
      <Box
        sx={{
          width: 400,
          height: 150,
          backgroundColor: "#FF8C00",
        }}
      >
        <p className="box-value">
          Welcome Admin User, The Page Is Under construction
        </p>
      </Box>
      {}
    </div>
  );
}
