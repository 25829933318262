import React from 'react'
import Box from '@mui/material/Box';
import './manager.css';

export default function manager() {
    return (
        <div>
            <Box
                sx={{
                    width: 400,
                    height: 150,
                    backgroundColor: '#FF8C00',
                }}
            >
                <p className="box-value">Welcome Manager User, The Page Is Under construction</p>
            </Box>
            {}
        </div>
    )
}
