import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect, useRef } from "react";
import Landing from "./components/Front_Page";
import Admin from "./components/admin";

const Home = () => {
  const { authState, oktaAuth, authService } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then(info => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  if (!authState) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <div>
        {authState.isAuthenticated && !userInfo && (
          <div>Loading user information...</div>
        )}

        {authState.isAuthenticated && userInfo && (
          <div>
            <Landing />
          </div>
        )}
        {!authState.isAuthenticated && {
          login: oktaAuth.signInWithRedirect(),
        }}
      </div>
    </div>
  );
};
export default Home;
