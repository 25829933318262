import React from "react";
import { useEffect, useState } from "react";
import AccessManagement from "./AccessManagement";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@mui/material";
import AuditManagement from "./AuditManagement";
import RumAdministrator from "./RumAdministrator";
import NewUserNote from "./NewUserMessage";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import Datasecurity from "./DataSecurity";

const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
  },
});

export default function Front_Page() {
  const classes = useStyles();
  const Url = process.env.BASE_URL;
  const Svc = process.env.SVC_ACCT;
  const API_KEY = process.env.RUM_API;
  const { oktaAuth } = useOktaAuth();
  var Email = oktaAuth.authStateManager._authState.idToken.claims.email;
  var Emails = oktaAuth.authStateManager._authState.idToken;

  const Admin = process.env.ADMIN_GROUP_ID;
  //const PolicyManager = "00g136t9okwj4IRQ50x8";
  const PolicyManager = process.env.POLICY_MANAGER_ID;
  const Authorisation = process.env.AUTHORIZATION;
  const params = new URLSearchParams([["email", Email]]);
  const [role, setRole] = useState([]);
  const [userLoading, setUserLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [item, setItem] = useState([]);

  var data = {
    activity_type: "login",
    oktaUserId: item.userId,
    email: item.email,
    message: "Successful User Log In",
  };

  const found = () => {
    let roleAccess;
    if (role.includes(Admin) && role.includes(PolicyManager)) {
      roleAccess = "all";
    } else if (role.includes(Admin)) {
      roleAccess = "admin";
    } else if (role.includes(PolicyManager)) {
      roleAccess = "policyManager";
    } else {
      roleAccess = "user";
    }
    return roleAccess;
  };
  const foundOld = role.filter((obj) => {
    return obj == Admin;
  });

  useEffect(() => {
    axios
      //.get(Url + `get-user-personas?email=rooksana.sultana@procore.com`, {
      .get(Url + `get-user-personas?email=${Email}`, {
        headers: {
          "rum-svc-acct": Svc,
          "rum-api-key": API_KEY,
        },
      })
      .then((data) => {
        setUserLoading(false);
        // var obj = {};
        // let option = [];
        // var objs = {};
        // let options = [];
        // var objects = {};
        // let optionss = [];
        // for (var i = 0; i < data.data.userPersonas.length; i++) {
        //   obj = data.data.userPersonas[0];
        //   setItem(obj);
        // }
        // for (var i = 0; i < data.data.userPersonas.length; i++) {
        //   obj = data.data.userPersonas[i].personas;
        //   option.push(obj);
        // }
        // for (var i = 0; i < option[0].length; i++) {
        //   objs = option[0][i].groups;
        //   options.push(objs);
        // }

        // for (var i = 0; i < options.length; i++) {
        //   objects = options[i][0].oktaGroupId;
        //   optionss.push(objects);
        // }
        const userPersonas = data.data.userPersonas;
        const userRoles = userPersonas.map((ele) =>
          ele.personas.map((persona) => persona.groups[0].oktaGroupId)
        );

        setRole(userRoles[0]);
      })
      .catch((err) => {
        setUserLoading(false);
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (item.email) {
      axios
        .post(Url + `login-notification`, data, {
          headers: {
            "rum-svc-acct": Svc,
            "rum-api-key": API_KEY,
          },
        })
        .then((data) => {
          if (data.status == 200) {
            return data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [item]);
  useEffect(() => {
    let roleAccess = found();
    setUserRole(roleAccess);
  }, [role]);
  return (
    <Container maxWidth="lg" className="myContainer">
      <Grid container spacing={2} justifyContent="space-between">
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <span
            style={{ width: "75%", fontSize: "18px" }}
            className="frontWelcomeNote"
          >
            Welcome to <b>R</b>ole <b>U</b>ser <b>M</b>anager (<b>RUM</b>).
            <br /> You can use <b>RUM</b> to request and understand access to
            different data systems. <br />
            Please review the options below for Access and Audit Management.
          </span>
        </div>
        {!userLoading && (
          <Grid item lg={6}>
            <div style={{ padding: "1rem", width: "1200px" }}>
              <AccessManagement />
            </div>
            <div style={{ padding: "1rem", width: "1200px" }}>
              <AuditManagement />
            </div>
          
            {userRole === "all" && (
              <>
                <div style={{ padding: "1rem", width: "1200px" }}>
                  <RumAdministrator />
                </div>
                <div style={{ padding: "1rem", width: "1200px" }}>
                  <Datasecurity />
                </div>
              </>
            )}
            {userRole === "admin" && (
              <div style={{ padding: "1rem", width: "1200px" }}>
                <RumAdministrator />
              </div>
            )}
            {userRole === "policyManager" && (
              <div style={{ padding: "1rem", width: "1200px" }}>
                <Datasecurity />
              </div>
            )}
            <div style={{ padding: "1rem", width: "1200px" }}>
              <NewUserNote />
            </div>
          </Grid>
        )}
        {console.log(found(),"roleAccess")}
      </Grid>
    </Container>
  );
}